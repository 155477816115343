import axios from "axios";
import { apiClient } from ".";
import { PatientStatus, Project, ProjectDetail, ProjectMeta, ProjectUser } from "../interfaces";
import { UserWithProject } from "../store/data/types";

export interface RecentDocumentStatus {
  status: string;
  message: string;
}

export interface RecentDocument {
  bucketName: string;
  documentCompletedOn: string;
  documentCreatedOn: string;
  documentId: string;
  documentStatus: string;
  objectName: string;
  statusList: RecentDocumentStatus[];
}

interface FileResponse {
  count: number;
  list: RecentDocument[];
  requestID: string;
  LastEvaluatedKey?: {
    documentId: string;
  };
}

interface FileListResponse {
  message: string;
  response: FileResponse;
}

export interface GetAllProjectResponse {
  data: Project[];
  message: string;
}

export interface AddEditProjectRequest {
  name: string;
  description: string;
  s3Folder: string;
  meta?: ProjectMeta[];
}

export interface GetOneProjectResponse {
  data: Project;
  message: string;
}

export interface ProjectDetailResponse {
  data: ProjectDetail;
  message: string;
}

export interface GetUsersWithProjectsResponse {
  data: UserWithProject[];
  message: string;
}

export interface PatientStatusResponse {
  data: PatientStatus[];
  message: string;
}

export interface ProjectUserResponse {
  data: ProjectUser;
  message: string;
}

export interface UpdatePatientStatusResponse {
  data: {
    CreatedBy: number;
    Id: number;
    IsDeleted: boolean;
    OrganizationId: number;
    PatientProjectId: number;
    ProjectStatusId: number;
    UpdatedBy: number;
    createdAt: string;
    updatedAt: string;
    updatedBy: string;
  };
  message: string;
}

export interface GetS3UploadUrlResponse {
  message: string;
  response: string;
}

export interface ProjectDashboardStats {
  status: string;
  count: number;
  colorCode: string;
}

interface ProjectDashboardStatsResponse {
  message: string;
  data: ProjectDashboardStats[];
}

interface DeleteProjectMetaResponse {
  data: {
    status: string;
    meta: ProjectMeta[];
  };
  message: string;
}

const getAll = async (organisationId: number) => {
  const res = await apiClient.get<GetAllProjectResponse>(`/project/data/${organisationId}`);
  return res;
};

const getFiles = async (projectId: number, pageSize = 100, indexKey = "") => {
  const res = await apiClient.get<FileListResponse>(
    `/filemanager/list/${projectId}/${pageSize}/${indexKey ? indexKey : ""}`
  );
  return res;
};

const getS3UploadURL = async (projectId: number, fileName: string) => {
  const res = await apiClient.get<GetS3UploadUrlResponse>(
    `/filemanager/uploadURL?projectid=${projectId}&filename=${fileName}`
  );
  return res;
};

const uploadFiles = async (url: string, file: any) => {
  const form = new FormData();
  form.append("file", file);
  const res = await axios({
    method: "put",
    url,
    data: form,
    headers: { "Content-Type": `multipart/form-data;` },
  });
  return res;
};

const getOne = async (clientId: number, projectId: string) => {
  const res = await apiClient.get<ProjectDetailResponse>(`/project/data/${clientId}/${projectId}`);
  return res;
};

const editOne = async (clientId: number, projectId: number, reqObj: AddEditProjectRequest) => {
  const res = await apiClient.put<GetOneProjectResponse>(
    `/project/data/${clientId}/${projectId}`,
    reqObj
  );
  return res;
};

const addOne = async (clientId: number, reqObj: AddEditProjectRequest) => {
  const res = await apiClient.post<GetOneProjectResponse>(`/project/data/${clientId}`, reqObj);
  return res;
};

const deleteOne = async (clientId: number, projectId: number) => {
  const res = await apiClient.delete(`/project/data/${clientId}/${projectId}`);
  return res;
};

const deleteProjectMeta = async (projectId: number, fieldKey: string) => {
  const res = await apiClient.delete<DeleteProjectMetaResponse>(
    `/project/meta/${projectId}/${fieldKey}`
  );
  return res;
};

const addUser = async (clientId: number, projectid: number, userid: number) => {
  const res = await apiClient.post(`/project/user/${clientId}`, { projectid, userid });
  return res;
};

const removeUser = async (clientId: number, projectid: string, userid: number) => {
  const res = await apiClient.delete(`/project/user/${clientId}`, {
    data: {
      projectid: Number(projectid),
      userid,
    },
  });
  return res;
};

const disableEnable = async (clientId: number, status: boolean, id: number) => {
  const reqObj = { status };
  const res = await apiClient.put<GetOneProjectResponse>(
    `/project/data/${clientId}/status/${id}`,
    reqObj
  );
  return res;
};

const getUsersWithProjects = async (clientId: number) => {
  const res = await apiClient.get<GetUsersWithProjectsResponse>(`/project/user/${clientId}/list`);
  return res;
};

const disableEnableProjectUsers = async (relationId: number, status: boolean) => {
  const reqObj = { status };
  const res = await apiClient.put<ProjectUserResponse>(`/project/user/${relationId}`, reqObj);
  return res;
};

const getPatientStatusList = async (clientId: number) => {
  const res = await apiClient.get<PatientStatusResponse>(`/project/status/list/${clientId}`);
  return res;
};

const updatePatientStatus = async (projectStatusId: number, patientProjectId: number) => {
  const res = await apiClient.put<UpdatePatientStatusResponse>(
    `/project/patient/${projectStatusId}/${patientProjectId}`
  );
  return res;
};

const getProjectDashboardStats = async (clientId: number, projectId: number) => {
  const res = await apiClient.get<ProjectDashboardStatsResponse>(
    `dashboard/status/project/${clientId}/${projectId}`
  );
  return res;
};

export const projectService = {
  getAll,
  getOne,
  editOne,
  addOne,
  deleteOne,
  disableEnable,
  getFiles,
  addUser,
  removeUser,
  getUsersWithProjects,
  disableEnableProjectUsers,
  getPatientStatusList,
  updatePatientStatus,
  getProjectDashboardStats,
  getS3UploadURL,
  uploadFiles,
  deleteProjectMeta,
};
