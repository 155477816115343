import React, { Dispatch, useEffect, useState } from "react";
import { Switch, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { User, usersService } from "../../api";
import {
  deleteUser,
  disableEnableUser,
  openModal,
  RootState,
  setUserEditDelete,
  updateDeleteConfirmation,
} from "../../store";
import { MODAL_TYPE } from "../../utils";
import { ColumnsType } from "antd/lib/table";
import { AnyAction } from "redux";
import { EditIcon } from "../../assets/icons";

interface Props {
  loading: boolean;
}

interface DataType {
  key: React.Key;
  name: string;
  email: string;
  action: string;
  role: string;
  status: boolean;
}

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<DataType> => [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
  {
    title: "Role",
    key: "role",
    dataIndex: "role",
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (value, record) => {
      const onChange = async (status: boolean) => {
        const { data } = await usersService.disableEnable(status, Number(record.key));
        dispatch(disableEnableUser({ userId: data.data.id, status: data.data.status }));
      };
      return (
        <div className="editDelete aic jcc gp flex">
          <Switch size="small" checked={record.status} onChange={onChange} />
          <EditIcon
            className={record.status ? "cursor-pointer" : "cursor-disable"}
            onClick={() => {
              if (record.status) {
                dispatch(setUserEditDelete({ id: Number(record.key), isEdit: true }));
                dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER));
              }
            }}
          />
          <p
            className={record.status ? "cursor-pointer" : "cursor-disable"}
            onClick={() => {
              if (record.status) {
                dispatch(setUserEditDelete({ id: Number(record.key), isEdit: true }));
                dispatch(openModal(MODAL_TYPE.RESET_USER_PASSWORD));
              }
            }}
          >
            Reset Password
          </p>
        </div>
      );
    },
  },
];

export const UsersList = ({ loading: initialLoading }: Props) => {
  const dispatch = useDispatch();
  const { id: deleteUserId, list: users } = useSelector(
    (state: RootState) => state.data.users
  ).users;
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const [loading, setLoading] = useState(false);

  const data: DataType[] = users.map((item) => {
    const { email, id, firstName, lastName, status, visualName: role } = item;
    return {
      key: id,
      name: firstName + " " + lastName,
      email,
      action: "",
      status,
      role,
    };
  });

  useEffect(() => {
    const deleteUserAPI = async () => {
      setLoading(true);
      await usersService.deleteOne(deleteUserId);
      dispatch(deleteUser({ id: deleteUserId }));
      dispatch(updateDeleteConfirmation(false));
      setLoading(false);
    };

    if (isDeleteConfirmed && deleteUserId !== -1) deleteUserAPI();
  }, [deleteUserId, dispatch, isDeleteConfirmed]);

  return (
    <Table
      className="user-table"
      pagination={false}
      dataSource={data}
      columns={getColumns(dispatch)}
      loading={loading || initialLoading}
    />
  );
};
