import React from "react";
import { Project } from "../../../interfaces";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";
import { ProjectActions } from "./ProjectActions";
import { PAGE_URL, getLocalDateTimeStringFromISO } from "../../../utils";

interface Props {
  project: Project;
}

export const ProjectTile = ({ project }: Props) => {
  const navigate = useNavigate();
  const projectDescription =
    project.Description.slice(0, -4) + (project.Description.length > 300 ? "..." : "");
  return (
    <Card className="project-card-full project-card-view p-20">
      <div className="flex jcsb m-b">
        <p className="project-name">{project.Name}</p>
        <ProjectActions project={project} />
      </div>
      <div
        className="cursor-pointer project-card-footer flex flex-column"
        onClick={() => navigate(`${PAGE_URL.PROJECT_DETAIL}/${project.Id}`)}
      >
        <div className="project-card-view-dis cursor-pointer">
          <h5>Project Description</h5>
          <p dangerouslySetInnerHTML={{ __html: projectDescription }} />
        </div>
      </div>
      

      <div className="flex jcsb aic pt-20 mt-auto">
        <p>
          <span className="highlight">Created on </span>
          {getLocalDateTimeStringFromISO(project.createdAt)}
        </p>
        <p>
          <span className="highlight">Updated on </span>
          {getLocalDateTimeStringFromISO(project.updatedAt)}
        </p>
      </div>
    </Card>
  );
};
