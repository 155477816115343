import React from "react";

interface Props {
  children: React.ReactNode;
  title: string;
}

export const HEDISMeasureInsightTileWrapper = ({ children, title }: Props) => {
  return (
    <>
      <h4 className="insight-card-title" id={title.toLowerCase().split(" ").join("_")}>
        {title.toUpperCase()}
      </h4>
      <div className="ant-card p-20">{children}</div>
    </>
  );
};
