import React from "react";
import { IHEDISMeasureInsight } from "../../../../interfaces";
import { Row, Col } from "antd";
import {
  BreastCancerScreeningTile,
  CervicalCancerScreeningTile,
  ColorectalCancerScreeningTile,
  ComprehensiveDiabetesCareTile,
} from "./hedis-measure-insights-tiles";
import { IPatient } from "../../../../interfaces";

interface Props {
  hedisMeasures: IHEDISMeasureInsight;
  patientInfo: IPatient;
}

export const HEDISMeasureInsightsTiles = ({ hedisMeasures, patientInfo }: Props) => {
  const { hedis_col, hedis_bcs, hedis_ccs, hedis_cdc } = hedisMeasures;
  return (
    <div className="m-t">
      <Row gutter={[40, 40]}>
        <Col className="gutter-row" span={24}>
          {hedis_bcs && (
            <BreastCancerScreeningTile
              patientId={patientInfo.patientId}
              breastCancerScreening={hedis_bcs}
            />
          )}
        </Col>
        <Col className="gutter-row" span={24}>
          {hedis_col && (
            <ColorectalCancerScreeningTile
              patientId={patientInfo.patientId}
              colorectalCancerScreening={hedis_col}
            />
          )}
        </Col>
        <Col className="gutter-row" span={24}>
          {hedis_ccs && (
            <CervicalCancerScreeningTile
              patientId={patientInfo.patientId}
              cervicalCancerScreening={hedis_ccs}
            />
          )}
        </Col>
        <Col className="gutter-row" span={24}>
          {hedis_cdc && (
            <ComprehensiveDiabetesCareTile
              patientId={patientInfo.patientId}
              comprehensiveDiabetesCare={hedis_cdc}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
