import React from "react";
import { IKeyword } from "../../../../../interfaces";

interface Props {
  keyword: IKeyword;
}

export const HedisKeywords = ({ keyword }: Props) => {
  return (
    <div>
      <h4>Included keywords</h4>
      <div className="">
        {Object.keys(keyword).length !== 0 ? (
          <ul className="quick-links-list mb-0 flex gp-10">
            {Object.keys(keyword).map(
              (item, index) => keyword[item] !== 0 && <li key={`keyword-${index}`}>{item}</li>
            )}
          </ul>
        ) : (
          <div>No keywords found</div>
        )}
      </div>
    </div>
  );
};
