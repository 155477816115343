import { Spin } from "antd";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

/** DOCS { https://github.com/ankeetmaini/react-infinite-scroll-component#readme } */

interface Props {
  isInfiniteScrollOn: boolean;
  dataLength: number;
  onUpdate: () => Promise<void>;
  children: React.ReactNode;
  hasMore: boolean;
}

export const InfiniteScrollWrapper = ({
  children,
  dataLength,
  isInfiniteScrollOn,
  onUpdate,
  hasMore,
}: Props) => {
  if (!isInfiniteScrollOn) return <>{children}</>;

  return (
    <div>
      <InfiniteScroll
        dataLength={dataLength} //This is important field to render the next data
        next={onUpdate}
        hasMore={hasMore}
        loader={<Spin tip="Loading..." />}
      >
        {children} {/** !!! DO NOT TOUCH !!! => It wraps the table */}
      </InfiniteScroll>
    </div>
  );
};
