import React, { useCallback, useEffect, useState } from "react";
import { Card, Modal } from "antd";
import { Button } from "antd";
import { UserMultipleRolesProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import "../modal.scss";
import "./user-mutliple-roles.scss";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../../../utils";
import { Radio, Space } from "antd";
import { rolesService } from "../../../api/roles";
import { UserRole } from "../../../interfaces";

const UserMultipleRoles = ({
  visibility,
  handleClose,
  impersonateUser,
  impersonation,
  removeImpersonation,
}: UserMultipleRolesProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([] as UserRole[]);
  const [selectedRole, setSelectedRole] = useState("");

  const handleContinue = () => {
    let roleName = "";
    const index = userRoles.findIndex((item) => item.RoleCode === selectedRole);
    if (index !== -1) roleName = userRoles[index].VisualName;
    impersonateUser(selectedRole, roleName);
    navigate(PAGE_URL.DASHBOARD);
    handleClose();
  };

  const handleRemove = () => {
    removeImpersonation();
    navigate(PAGE_URL.DASHBOARD);
    handleClose();
  };

  const fetchUserRoles = useCallback(async () => {
    setLoading(true);
    const { data } = await rolesService.getAll(true);
    setUserRoles(data.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUserRoles();
  }, [fetchUserRoles]);

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={580}
      title="User Roles"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Pick the new user role to switch to</h1>
      </div>
      {/* <div className="multiple-role"> */}
      {/* <h1>Select a role to impersonate</h1> */}
      <div className="addClient-content">
        <Card className="multiple-role-radio" loading={loading}>
          <Radio.Group
            onChange={(e) => setSelectedRole(e.target.value)}
            defaultValue={impersonation && impersonation.isActive ? impersonation.roleCode : ""}
          >
            <Space className="gp-none" direction="vertical">
              {userRoles.map((item, index) => (
                <Radio key={`user-imperonsate-role-${index}`} value={item.RoleCode}>
                  {item.VisualName}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Card>
        <div className="tr">
          <Button className="outline" onClick={handleContinue} disabled={loading}>
            Continue
          </Button>

          {impersonation && impersonation.isActive && (
            <Button className="m-l-20 outline" onClick={handleRemove} disabled={loading}>
              Switch to original user role
            </Button>
          )}
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default UserMultipleRoles;
