export const getCurrentDateTime = () =>
  new Date().toLocaleDateString() + ", " + new Date().toLocaleTimeString();

export const getDate = (date: Date) => new Date(date).toISOString().split("T")[0];

export const getTime = (date: Date) =>
  `${
    date
      .toLocaleDateString("en-us", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })
      .split(",")[1]
  }`;

export const getLocalDateTimeStringFromISO = (datetime: string) => {
  if (!datetime) return "-";
  const date = new Date(datetime);
  return `${getDate(date)} ${getTime(date)}`;
};

export function getAge(dateString: string) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
