import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { ConfigurationObj } from "../../api";
import { useForm } from "antd/lib/form/Form";
import { AWS_SERVICE_CONFIGURATION_KEYS } from "./mail-service-configuration/constant";
import { Loading, MandatoryFieldsText } from "../../components";
import { useGlobalConfiguration } from "../../hooks";
import "./service-config.scss";

export const AWSServiceConfiguration = () => {
  const [form] = useForm();
  const [disableSave, setDisableSave] = useState(true);
  const {
    configurations,
    fetchConfigurations,
    loading,
    updateConfigLoading,
    updateConfigurations,
  } = useGlobalConfiguration();

  const updateForm = useCallback(
    (data: ConfigurationObj[]) => {
      const key = [
        AWS_SERVICE_CONFIGURATION_KEYS.awsDynamoDBTable,
        AWS_SERVICE_CONFIGURATION_KEYS.awsS3Bucket,
      ];
      const obj: { [key: string]: string } = {};
      key.forEach((item) => {
        const index = data.findIndex((config) => config.field_key === item);
        if (index !== -1) {
          const content = data[index].field_content;
          obj[data[index].field_key] = Array.isArray(content) ? "" : content;
        }
      });
      form.setFieldsValue({ ...obj });
    },
    [form]
  );

  useEffect(() => {
    fetchConfigurations();
  }, [fetchConfigurations]);

  useEffect(() => {
    updateForm(configurations);
  }, [configurations, updateForm]);

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleSubmit = async (e: any) => {
    if (disableSave) return;
    const awsS3Bucket = e.target[0].value;
    const awsDynamoDBTable = e.target[1].value;
    const reqObj: ConfigurationObj[] = [
      {
        field_content: awsS3Bucket,
        field_key: AWS_SERVICE_CONFIGURATION_KEYS.awsS3Bucket,
        field_name: AWS_SERVICE_CONFIGURATION_KEYS.awsS3Bucket.split("_").join(" "),
      },
      {
        field_content: awsDynamoDBTable,
        field_key: AWS_SERVICE_CONFIGURATION_KEYS.awsDynamoDBTable,
        field_name: AWS_SERVICE_CONFIGURATION_KEYS.awsDynamoDBTable.split("_").join(" "),
      },
    ];
    await updateConfigurations(reqObj);
  };

  return (
    <div>
      <p className="table-heading">AWS Configuration</p>
      {loading ? (
        <Loading />
      ) : (
        <div className="ant-card">
          <Form
            // className="form-grid"
            layout="vertical"
            form={form}
            onSubmitCapture={handleSubmit}
            onFieldsChange={handleFormChange}
            colon={false}
            requiredMark={false}
          >
            <Form.Item
              name={AWS_SERVICE_CONFIGURATION_KEYS.awsS3Bucket}
              label="AWS S3 Bucket"
              rules={[{ required: true, message: "This field cannot be blank" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={AWS_SERVICE_CONFIGURATION_KEYS.awsDynamoDBTable}
              label="AWS Dynamo DB Table"
              rules={[{ required: true, message: "This field cannot be blank" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item className="mandatory-text">
              <MandatoryFieldsText />
              <Button
                // type="primary"
                htmlType="submit"
                className="outline"
                disabled={disableSave}
                loading={updateConfigLoading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};
