import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePagination } from "../../../hooks";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import { IQA, QuestionAnsweringService } from "../../../api/questionAnswering";
import { MODAL_TYPE, getOffsetFromPageAndPageSize, openNotificationWithIcon } from "../../../utils";
import { Loading } from "../../../components";
import { IPatient } from "../../../interfaces";
import { Button } from "antd";
import "./askQuestion.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  openModal,
  setQuestionsAnswers,
  updateDeleteConfirmation,
  resetQuestionAnswerState,
} from "../../../store";

interface Props {
  patientInfo: IPatient;
  setAskAQuestionLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QAHistory = ({ patientInfo, setAskAQuestionLoading }: Props) => {
  const [qaHistory, setQAHistory] = useState([] as IQA[]);
  const [totalQA, setTotalQA] = useState(0);
  const { onPaginationChange, page, pageSize } = usePagination();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [callApi, setCallApi] = useState(true);
  const dispatch = useDispatch();
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);

  const fetchQAHistory = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await QuestionAnsweringService.getQAHistory({
        offset: getOffsetFromPageAndPageSize(page, pageSize),
        limit: pageSize,
        patientId: patientInfo.patientId,
      });
      setQAHistory((prevState) => [...prevState, ...data.data]);
      setTotalQA(data.pagination.totalCount ?? 0);
    } catch (error: any) {
      navigate(-1);
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  }, [navigate, page, pageSize, patientInfo.patientId]);

  useEffect(() => {
    if (callApi) {
      fetchQAHistory();
      setCallApi(false);
    }
  }, [fetchQAHistory, callApi]);

  useEffect(() => {
    const clearHistory = async () => {
      try {
        await QuestionAnsweringService.clearHistory(patientInfo.patientId);
        setQAHistory([]);
        dispatch(updateDeleteConfirmation(false));
        dispatch(resetQuestionAnswerState(patientInfo.patientId.toString()));
        openNotificationWithIcon("", "History deleted successfully", "success");
      } catch (error: any) {
        openNotificationWithIcon("", error.response.data.message, "error");
      }
    };
    if (isDeleteConfirmed) clearHistory();
  }, [dispatch, isDeleteConfirmed, patientInfo.patientId]);

  const handleRefreshClick = () => {
    setQAHistory([]);
    onPaginationChange(1, pageSize);
    setCallApi(true);
  };

  const handleQuesClick = async (logId: number) => {
    setAskAQuestionLoading(true);
    try {
      const { data } = await QuestionAnsweringService.getSingleQa({
        logId,
        patientId: patientInfo.patientId,
      });
      dispatch(setQuestionsAnswers([{ answer: data.data.Answer, question: data.data.Question }]));
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setAskAQuestionLoading(false);
    }
  };

  const showMoreClick = () => {
    onPaginationChange(page + 1, pageSize);
    setCallApi(true);
  };

  return (
    <div className="ask-question-right-container">
      <div className="flex jcsb aic m-b">
        <h4 className="mb-0">Chat History</h4>
        <div className="flex aic gp">
          <a title="Refresh" onClick={handleRefreshClick}>
            <ReloadOutlined /> Refresh
          </a>
          {qaHistory.length !== 0 && (
            <a
              title="Clear Chat History"
              onClick={() => dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION))}
            >
              <DeleteOutlined /> Clear
            </a>
          )}
        </div>
      </div>
      <>
        {qaHistory.length > 0 ? (
          <div className="question-item-list">
            {qaHistory.map((qa, index) => (
              <div
                className="cursor-pointer"
                key={`qa-history-${index}`}
                onClick={() => handleQuesClick(qa.Id)}
              >
                <h4 title={qa.Question} className="question-item text_ellipsis">
                  {qa.Question}
                </h4>
              </div>
            ))}
            {qaHistory.length !== totalQA && (
              <div className="tc m-t">
                <Button className="outline" onClick={showMoreClick} loading={isLoading}>
                  Show More
                </Button>
              </div>
            )}
          </div>
        ) : (
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <p>No Chat history available now. Type your first question to begin the chat.</p>
            )}
          </>
        )}
      </>
    </div>
  );
};
