import React, { useMemo } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Modal, Progress, Steps, Button } from "antd";
import { ProjectDetailProcessingDetailStatusProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import {
  DOCUMENT_STATUS,
  getLocalDateTimeStringFromISO,
  roundToNearestNumber,
} from "../../../utils";
import "../modal.scss";

const { Step } = Steps;

const getTotalSteps = () => {
  let count = 0;
  for (const item in DOCUMENT_STATUS) {
    if (isNaN(Number(item))) count++;
  }
  // remove error status
  return count - 1;
};

const RetryMessage = () => {
  return (
    <span className="re-try">
      <div className="ant-steps-item-tail"></div>
      <p>The uploading failed. Please retry.</p>
      <Button className="outline" icon={<ReloadOutlined />} disabled={true}>
        Retry
      </Button>
    </span>
  );
};

const ProjectDocumentProcessingDetailStatus = ({
  visibility,
  documentDetail,
  handleClose,
}: ProjectDetailProcessingDetailStatusProps) => {
  const stepsProgress = useMemo(() => {
    const steps = documentDetail.statusList.length;
    // if (documentDetail.statusList[steps - 1].status === DOCUMENT_STATUS.ERROR) steps = steps - 1;
    return steps;
  }, [documentDetail.statusList]);

  return (
    <Modal
      className="autonomizeModal addClient"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="uploading-status">
        <p>File Processing Status</p>
        <Progress
          percent={roundToNearestNumber((stepsProgress / getTotalSteps()) * 100, 5)}
          status={
            stepsProgress > 1 &&
            documentDetail.statusList[stepsProgress - 1].status
              .toUpperCase()
              .includes(DOCUMENT_STATUS.ERROR)
              ? "exception"
              : stepsProgress === getTotalSteps()
              ? "success"
              : "active"
          }
        />
        {documentDetail && documentDetail.statusList?.length > 0 && (
          <div className="uploading-status-description">
            <Steps direction="vertical">
              {documentDetail.statusList.map((item, index) => (
                <Step
                  key={`document-status-${index}`}
                  title={item.status}
                  subTitle={item.message}
                  description={
                    item.status === DOCUMENT_STATUS.FILE_UPLOADED ? (
                      getLocalDateTimeStringFromISO(documentDetail.documentCreatedOn)
                    ) : item.status === DOCUMENT_STATUS.COMPLETED ? (
                      getLocalDateTimeStringFromISO(documentDetail.documentCompletedOn)
                    ) : item.status.toUpperCase().includes(DOCUMENT_STATUS.ERROR) ? (
                      <RetryMessage />
                    ) : (
                      ""
                    )
                  }
                  status={
                    item.status.toUpperCase().includes(DOCUMENT_STATUS.ERROR) ? "error" : "finish"
                  }
                />
              ))}
            </Steps>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ProjectDocumentProcessingDetailStatus;
