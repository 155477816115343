import React from "react";
import { PATIENT_INSIGHT_TILES, PATIENT_PROJECT_SPECIFIC_TILES } from "..";

interface Props {
  displayProjectSpecificTiles: boolean;
}

export const PatientInsightsQuickLinks = ({ displayProjectSpecificTiles }: Props) => {
  return (
    <div className="quick-links">
      <ul className="quick-links-list mb-0 flex">
        {displayProjectSpecificTiles &&
          Object.values(PATIENT_PROJECT_SPECIFIC_TILES).map((tile, index) => (
            <li key={`patient-project-tile-link-${index}`}>
              <a href={`#${tile.toLocaleLowerCase().split(" ").join("_")}`}>{tile}</a>
            </li>
          ))}
        {Object.values(PATIENT_INSIGHT_TILES).map((tile, index) => (
          <li key={`patient-insight-tile-link-${index}`}>
            <a href={`#${tile.toLocaleLowerCase().split(" ").join("_")}`}>{tile}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};
