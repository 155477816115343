import React from "react";
import {
  PageWrapper,
  SearchAside,
  SearchHome,
  SearchLayoutContainer,
  SearchResult,
} from "../containers";
import "../assets/sass/layout/search.scss";

export const SearchLayout = () => {
  return (
    <PageWrapper
      pageLeftAsideChildren={<SearchAside />}
      pageContainerChildren={<SearchLayoutContainer />}
    />
  );
};

const SearchPage = {
  Layout: SearchLayout,
  Home: SearchHome,
  Result: SearchResult,
};

export default SearchPage;
