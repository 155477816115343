import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { autonomizeFullLogo, autonomizeFullLogoBlack } from "../assets/images";
import { useUser } from "../hooks";
import { PAGE_URL } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState, resetPreviousEmailAndUrl } from "../store";

export const GuestLayout = () => {
  const navigate = useNavigate();
  const { accessToken } = useUser();
  const dispatch = useDispatch();
  const { previousEmail, previousURL, user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      if (previousURL && previousEmail && previousEmail === user.email) {
        navigate(previousURL);
      } else {
        navigate(PAGE_URL.DASHBOARD);
      }
      dispatch(resetPreviousEmailAndUrl());
    }
  }, [accessToken, navigate, dispatch]);

  return (
    <div className="user-page flex aic jcc">
      <div className="user-page-container flex flex-column gp-40">
        <div className="brand-logo m-b flex">
          <img className="brand-logo-img" src={autonomizeFullLogo} alt="Autonomize Logo" />
          <h1 className="brand-title">
            W<span className="brand-title-highlight">i</span>zard
          </h1>
        </div>
        <Outlet />
      </div>
    </div>
  );
};
