import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setSavedSearch } from "../../../store";
import { searchService } from "../../../api";
import { Spin } from "antd";
import { PAGE_URL } from "../../../utils";
import { SEARCH_HEADING } from "../search-aside";

export const SavedSearches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { saved } = useSelector((state: RootState) => state.search);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const { data } = await searchService.getSaved();
      dispatch(setSavedSearch(data.data));
      setLoading(false);
    };
    fetch();
  }, [dispatch]);

  return (
    <div className={`saved-searches dashboard-topSearch`}>
      <h3>{SEARCH_HEADING.SAVED}</h3>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <ul className="scrollBar">
          {saved.map((item, i) => (
            <li key={`saved-searches-${i}`} className="searches-li">
              <span
                className="searches-title"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate({
                    pathname: `${PAGE_URL.SEARCH.LAYOUT}/${PAGE_URL.SEARCH.RESULT}`,
                    search: createSearchParams({
                      auto: "true",
                      search: item.query.join(","),
                      type: "All",
                    }).toString(),
                  })
                }
              >
                {[...item.query].slice(0, 3).join(", ")}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
