import React from "react";
import { IQA } from "../../../../../interfaces";

interface Props {
  qa: IQA;
}

export const QATag = ({ qa }: Props) => {
  return (
    <div>
      <ul>
        <li>
          <span className="bold">Question- </span> {qa.query}
        </li>
        <li>
          <span className="bold">Answer- </span>
          {qa.answer}
        </li>
      </ul>
    </div>
  );
};
