import React from "react";
import { Tabs } from "antd";
import { PATIENT_INSIGHT_TABS_NEW, PATIENT_INSIGHT_TABS_NEW_SUBJECT_WELL } from "..";
const { TabPane } = Tabs;

interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
}

export const PatientInsightsTabs = ({ onChange, selectedTab }: Props) => {
  const displayHedisMeasureAndQa = process.env.REACT_APP_DISPLAY_HEDIS_AND_QA === "true";
  return displayHedisMeasureAndQa ? (
    <div className="tabs m-b">
      <Tabs onChange={onChange} activeKey={selectedTab}>
        {Object.values(PATIENT_INSIGHT_TABS_NEW).map((key) => {
          if (key === PATIENT_INSIGHT_TABS_NEW.ASK_A_QUESTION) {
            return (
              <TabPane
                tab={
                  <div>
                    <span className="chip">{key}</span>
                  </div>
                }
                key={key}
              />
            );
          } else {
            return <TabPane tab={key} key={key} />;
          }
        })}
      </Tabs>
    </div>
  ) : (
    <div className="tabs m-b">
      <Tabs onChange={onChange} activeKey={selectedTab}>
        {Object.values(PATIENT_INSIGHT_TABS_NEW_SUBJECT_WELL).map((key) => (
          <TabPane tab={key} key={key} />
        ))}
      </Tabs>
    </div>
  );
};
