import React from "react";
import { ProjectStatus } from "../../../components";
import { DashboardData } from "../../../api";
import { TimePerChartTile } from "./TimePerChartTile";

interface Props {
  data: DashboardData;
}

export const Sidebar = ({ data }: Props) => {
  return (
    <aside className="d-sidebar">
      <ProjectStatus {...{ projectId: -1, showDropdown: true }} />
      {data.kpiSettings.kpi_time_per_card.length > 0 && (
        <div className="top-search-result time-per-chart">
          <TimePerChartTile />
        </div>
      )}
    </aside>
  );
};
