import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ResetUserPassword } from "./ResetUserPassword";
import { closeModal, RootState, setUserEditDelete } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { User } from "../../../api";

type StateProps = {
  visibility: boolean;
  editObj: User;
};
type DispatchProps = {
  handleClose: () => void;
};
export type ResetUserPasswordProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const isEdit = state.data.users.users.isEdit;
  const id = state.data.users.users.id;
  let editObj = {} as User;
  if (isEdit && id !== -1) {
    const obj = state.data.users.users.list.find((item) => item.id === id);
    if (obj) editObj = obj;
  }
  return {
    visibility: state.ui.modal[MODAL_TYPE.RESET_USER_PASSWORD],
    editObj,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(setUserEditDelete({ id: -1, isEdit: false }));
    dispatch(closeModal(MODAL_TYPE.RESET_USER_PASSWORD));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetUserPassword);
