import React, { useCallback, useEffect, useState } from "react";
import { Card, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setProjects } from "../../store";
import { ProjectDashboardStats, projectService } from "../../api";
import { USER_ROLES_CODE } from "../../utils";
import { CircularProgressbar } from "react-circular-progressbar";
import { useUser } from "../../hooks";
import { Loading } from "../Loading";
import "./project-status.scss";

interface Option {
  id: number;
  value: string;
}

interface ProjectDashboardStatsWithProgressCount extends ProjectDashboardStats {
  value: number;
}

const getClassname = (str: string) => {
  const status = str.toLowerCase();
  if (status.includes("not a fit")) return "not-fit";
  else if (status.includes("good fit")) return "good-fit";
  else if (status.includes("recruited")) return "recruited";
  else return "not-reviewed";
};

interface Props {
  projectId: number; // send -1 if showDropdown is true
  showDropdown: boolean;
}

export const ProjectStatus = ({ projectId, showDropdown }: Props) => {
  const dispatch = useDispatch();
  const projects = useSelector((state: RootState) => state.data.projects.list);
  const { clientId, roleCode } = useUser();
  const [projectStats, setProjectStats] = useState([] as ProjectDashboardStatsWithProgressCount[]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const displayDropdown = showDropdown && projectId === -1;

  const fetchProjectStats = useCallback(
    async (id: number) => {
      setLoading(true);
      const { data } = await projectService.getProjectDashboardStats(clientId, id);
      let value = 0;
      const res = data.data
        .sort((x, y) => {
          if (x.count > y.count) return 1;
          else if (x.count < y.count) return -1;
          else return 0;
        })
        .map((item) => {
          value = value + item.count;
          return { ...item, value };
        });
      setProjectStats([...res]);
      let sum = 0;
      data.data.forEach((item) => (sum += item.count));
      setTotal(sum);
      setLoading(false);
    },
    [clientId]
  );

  useEffect(() => {
    const fetch = async () => {
      if (displayDropdown) {
        setLoadingProjects(true);
        const { data } = await projectService.getAll(clientId);
        const { data: projectList } = data;
        dispatch(setProjects(projectList));
        if (projectList.length > 0) {
          fetchProjectStats(projectList[0].Id);
        }
        setLoadingProjects(false);
      } else {
        fetchProjectStats(projectId);
      }
    };
    fetch();
  }, [clientId, dispatch, fetchProjectStats, displayDropdown, projectId]);

  const onOptionChange = async (value: string, option: Option) => {
    await fetchProjectStats(option.id);
  };

  return (
    <div className="project-status">
      <h3 className="basic-detail-title">Project Status</h3>
      <Card className="basic-detail-content p-10">
        {loadingProjects ? (
          <Loading />
        ) : projects.length === 0 && displayDropdown ? (
          <p>
            {roleCode === USER_ROLES_CODE.CLINICIAN
              ? "You are not assigned to any project"
              : "Please create a project to view its status"}
          </p>
        ) : (
          <>
            {displayDropdown && (
              <div className="aic jcsb d-sidebar-title">
                <div className="ai-select-box">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="ai-select"
                    dropdownMatchSelectWidth={true}
                    defaultValue={projects.length > 0 ? projects[0].Name : ""}
                    options={projects.map((item) => ({ value: item.Name, id: item.Id }))}
                    loading={loading}
                    onSelect={onOptionChange}
                  />
                </div>
              </div>
            )}
            {loading ? (
              <Loading />
            ) : (
              <>
                <div className="project-status-progress" style={{ position: "relative" }}>
                  <div style={{ position: "absolute" }}>
                    <CircularProgressbar className="background" value={100} text={`${total}`} />
                  </div>
                  {projectStats.reverse().map((item, index) => {
                    return (
                      <div
                        style={{ position: "absolute" }}
                        key={`project-status-progressbar-${index}`}
                      >
                        <CircularProgressbar
                          value={(item.value * 100) / total}
                          className={`${getClassname(item.status)}`}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="ac no-wrap project-status-info-wrap jcsb">
                  {projectStats.map((item, index) => (
                    <div className="flex aic  " key={`project-dashboard-stats-${index}`}>
                      <span
                        className="edu-marker"
                        style={{
                          borderColor: item.colorCode,
                        }}
                      />
                      <h5 className="project-status-chip">{item.status}:</h5>
                      <h4 className="project-status-info">{item.count}</h4>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </Card>
    </div>
  );
};
