import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import {
  ProjectAndPatientTile,
  TopSearches,
  ProjectListInTableAndTile,
} from "./dashboard-container";
import { Sidebar } from "./dashboard-container/Sidebar";
import "./Clinician-and-data-admin-container.scss";
import { DashboardData, DashboardService } from "../../api";
import { useUser } from "../../hooks";

const initialData: DashboardData = {
  patient: {
    count: 0,
    status: {
      reviewed: 0,
      toBeReviewed: 0,
    },
  },
  project: {
    count: 0,
    status: {
      inProgress: 0,
      pending: 0,
    },
  },
  kpiSettings: {
    kpi_patient_stats_card: [""],
    kpi_project_stats_card: [""],
    kpi_time_per_card: [""],
  },
};

export const ClinicianAndDataAdminContainer = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const { clientId } = useUser();

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      const { data: res } = await DashboardService.getData(clientId);
      setData({ ...initialData, ...res.data });
      setLoading(false);
    };
    fetchDashboardData();
  }, [clientId]);

  return (
    <div className="dashboard">
      <div className="top-search-result">
        <Row className="top-search-result-item" gutter={[21, 20]}>
          <ProjectAndPatientTile loading={loading} data={data} />
        </Row>
        <Row className="top-search-result-item" gutter={[21, 20]}>
          <div className="projectsList">
            <ProjectListInTableAndTile className="scroll-small" />
          </div>
        </Row>
        <Row className="top-search-result-item" gutter={[21, 20]}>
          <Col span={24}>
            <h2>Top Searches</h2>
          </Col>
          <Col span={24}>
            <TopSearches />
          </Col>
        </Row>
      </div>
      <Sidebar data={data} />
    </div>
  );
};
