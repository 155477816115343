import React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import "../patientprofile.scss";
import {
  EVIDENCE_VIEWER_URL,
  PatientCardWrapper,
} from "../tile-helper-components";
import { ColumnsType } from "antd/lib/table";
import { PATIENT_INSIGHT_TILES } from "..";

interface DataType {
  key: string;
  value: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "",
    dataIndex: "value",
    key: "value",
  }
];

interface Props {
  threshold: number;
  patientId: number;
  patientName: string;
  dob: string;
  gender: string;
}

export const PatientDetailsCard = ({ patientId, threshold, patientName, dob, gender }: Props) => {

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`
    );
  };

  return (
    <>
      <PatientCardWrapper title="Patient Details">
        <div className="blue flex patient-card tiles-max-height-table">
            <Table
              columns={columns as ColumnsType<{
                key: string;
                value: string | number;
              }>}
              dataSource={
                [
                  {
                    key: "Patient Name",
                    value: patientName
                  },
                  {
                    key: "DOB",
                    value: dob != "" ? dob : "-"
                  },
                  {
                    key: "Gender",
                    value: gender != "" ? gender: "-"
                  },
                  {
                    key: "Patient ID",
                    value: patientId
                  }
                ]
              }
              pagination={false}
              className="cmn-table"
              rowClassName={"cursor-pointer"}
              onRow={(record) => ({ onClick: () => { } })}
            />
        </div>
      </PatientCardWrapper>
    </>
  );
};
