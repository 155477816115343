import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Loading } from "../../../components";
import { ProjectDetail } from "../../../interfaces";
import { openModal } from "../../../store";
import { isEmptyObject, MODAL_TYPE } from "../../../utils";
import { ProjectTeamMemberCard } from "./ProjectTeamMemberCard";
import { TeamIcon } from "../../../assets/icons/TeamIcon";

interface Props {
  projectDetail: ProjectDetail;
}

export const ProjectTeam = ({ projectDetail }: Props) => {
  const dispatch = useDispatch();

  return isEmptyObject(projectDetail) ? (
    <Loading />
  ) : (
    <div className="project-detail-container-right">
      <div className="flex jcsb aic project-detail-container-right-title">
        <p className="flex aic gp-10">
          <span>
            <TeamIcon />
          </span>
          Team
        </p>
        <Button
          className="fill"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_USER_TO_PROJECT));
          }}
          disabled={!projectDetail.status}
        >
          + Add team members
        </Button>
      </div>
      <div className="team-member-container m-t">
        {projectDetail.users.map((user, index) => (
          <ProjectTeamMemberCard key={index} user={user} />
        ))}
      </div>
    </div>
  );
};
