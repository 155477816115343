import React from "react";
import { UploadProjectDocuments } from "../project-detail-components";
import { RecentDocuments } from "../../tables";

interface Props {
  projectId: number;
}

export const ProjectDetailDocuments = ({ projectId }: Props) => {
  return (
    <>
      <UploadProjectDocuments projectId={projectId} />
      <RecentDocuments projectId={projectId} />
    </>
  );
};
