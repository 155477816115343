import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import type { DatePickerProps } from "antd";
import { Modal, DatePicker, Button } from "antd";
import moment from "moment";
import { AdvanceSearchParams } from "../../../api";
import { toTitleCase } from "../../../utils";
import "./search-modal.scss";

interface Props {
  modalState: {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
  };
  searchParams: AdvanceSearchParams;
}
const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  // console.log(date, dateString);
};

const dateRangeOptions = [
  {
    title: "Custom",
    difference: -1,
  },
  {
    title: "Today",
    difference: 0,
  },
  {
    title: "Yesterday",
    difference: 1,
  },
  {
    title: "Last 7 days",
    difference: 7,
  },
  {
    title: "Last 30 days",
    difference: 30,
  },
];

enum FooterClick {
  apply,
  reset,
  cancel,
  none,
}

interface FooterProps {
  setFooterClick: Dispatch<SetStateAction<FooterClick>>;
}

const Footer = ({ setFooterClick }: FooterProps) => {
  const onClick = (footerClick: FooterClick) => {
    setFooterClick(footerClick);
  };

  return (
    <div className="search-modal-footer">    
      <span className="search-modal-footer-link" onClick={() => onClick(FooterClick.reset)}>
        Reset
      </span>
      <span className="search-modal-footer-link" onClick={() => onClick(FooterClick.cancel)}>
        Cancel
      </span>
      <span className="search-modal-footer-apply" onClick={() => onClick(FooterClick.apply)}>
        Apply
      </span>
    </div>
  );
};

export const AdvanceSearchModal = ({ modalState, searchParams }: Props) => {
  const { visible, setVisible } = modalState;
  const [queryParams, setQueryParams] = useSearchParams();
  const navigate = useNavigate();
  const [defaultDateRange, setDefaultDateRange] = useState(dateRangeOptions[1].title);
  const [date, setDate] = useState({ start: new Date(), end: new Date() });
  const [type, setType] = useState(queryParams.get("type") || "All");
  const [diseases, setDiseases] = useState(queryParams.get("search")?.split(",") || []);
  const [gender, setGender] = useState(queryParams.get("gender") || "Both");
  const [ageGroup, setAgeGroup] = useState(queryParams.get("ageGroup")?.split(",") || ["All"]);
  const [location, setLocation] = useState(queryParams.get("location") || "All Countries");
  const [interventions, setInterventions] = useState(
    queryParams.get("interventions")?.split(",") || ["All"]
  );
  const [footerClick, setFooterClick] = useState(FooterClick.none);

  const updateQueryParams = useCallback(() => {
    const newQueryParams = {
      type,
      search: diseases.length > 0 ? diseases.join(",") : "",
      gender,
      ageGroup: ageGroup.length > 0 ? ageGroup.join(",") : "",
      location,
      interventions: interventions.length > 0 ? interventions.join(",") : "",
    };
    setQueryParams(newQueryParams);
    navigate(
      {
        search: createSearchParams(newQueryParams).toString(),
      },
      { replace: true }
    );
  }, [ageGroup, diseases, gender, interventions, location, navigate, setQueryParams, type]);

  const resetState = useCallback(() => {
    setType(queryParams.get("type") || "All");
    setDiseases(queryParams.get("search")?.split(",") || []);
    setGender(queryParams.get("gender") || "Both");
    setAgeGroup(queryParams.get("ageGroup")?.split(",") || ["All"]);
    setLocation(queryParams.get("location") || "All Countries");
    setInterventions(queryParams.get("interventions")?.split(",") || ["All"]);
  }, [queryParams]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  useEffect(() => {
    if (footerClick === FooterClick.apply) {
      updateQueryParams();
      setVisible(false);
    } else if (footerClick === FooterClick.reset) resetState();
    else if (footerClick === FooterClick.cancel) setVisible(false);
    setFooterClick(FooterClick.none);
  }, [footerClick, resetState, setVisible, updateQueryParams]);

  function handleMultipleOptionClick<T>(
    values: T[],
    setValues: Dispatch<SetStateAction<T[]>>,
    clickedValue: T
  ) {
    const newValues = values.includes(clickedValue)
      ? [...values.filter((val) => val !== clickedValue)]
      : [...values, clickedValue];
    setValues([...newValues]);
  }

  function handleSingleOptionClick<T>(newValue: T, setValue: Dispatch<SetStateAction<T>>) {
    setValue(newValue);
  }

  return (
    <Modal
      className="search-modal"
      title=""
      visible={visible}
      footer={<Footer setFooterClick={setFooterClick} />}
      closable={false}
      width={1000}
    >
      <h3 className="search-title">Patient Data received/loaded between</h3>
      <div className="search-modal-btn-group btn1-grp">
        <DatePicker className="modal-date" onChange={onChange} value={moment(date.start)} />
        <DatePicker className="modal-date" onChange={onChange} value={moment(date.end)} />
        {dateRangeOptions.map((item, index) => (
          <Button
            key={`advance-search-date-${index}`}
            type={item.title === defaultDateRange ? "primary" : "link"}
            className={`${
              item.title === defaultDateRange ? " modal-btn-fill" : "outline"
            }`}
            onClick={() => {
              setDefaultDateRange(item.title);
              if (item.difference >= 0) {
                const startDate = new Date();
                startDate.setDate(date.end.getDate() - item.difference);
                setDate({
                  ...date,
                  start: startDate,
                });
              }
            }}
          >
            {item.title}
          </Button>
        ))}
      </div>

      <div className="search-modal-group hr ">
      <h3 className="search-title">Type</h3>
        <div className="search-modal-btn-group">
          {searchParams.Type.map((item, index) => (
            <Button
              key={`advance-search-type-${index}`}
              className={`${item.key.toLowerCase() === type.toLowerCase() ? "modal-btn-fill" : ""}`}
              onClick={() => {
                handleSingleOptionClick(toTitleCase(item.key), setType);
              }}
            >
              {toTitleCase(item.key)}
            </Button>
          ))}
        </div>
      </div>
      <div className="search-modal-group hr">
      <h3 className="search-title">Diseases</h3>
        <div className="search-modal-btn-group">
          {searchParams.Diseases.data.slice(0, 32).map((item, index) => (
            <Button
              key={`advance-search-diseases-${index}`}
              className={`${diseases.includes(item) ? "modal-btn-fill" : ""}`}
              onClick={() =>
                handleMultipleOptionClick(
                  diseases.filter((val) => val.length > 0),
                  setDiseases,
                  item
                )
              }
            >
              {item}
            </Button>
          ))}
        </div>
      </div>
      <div className="search-modal-row hr">
        <div className="search-modal-group">
        <h3 className="search-title">Gender</h3>
          <div className="search-modal-btn-group">
            {searchParams.Gender.map((item, index) => (
              <Button
                key={`advance-search-gender-${index}`}
                className={`${
                  item.key.toLowerCase() === gender.toLowerCase() ? "modal-btn-fill" : ""
                }`}
                onClick={() => handleSingleOptionClick(toTitleCase(item.key), setGender)}
                disabled={index !== 0}
              >
                {toTitleCase(item.key)}
              </Button>
            ))}
          </div>
        </div>

        <div className="search-modal-group">
        <h3 className="search-title">Age Group</h3>
          <div className="search-modal-btn-group">
            {searchParams.ageGroup.data.map((item, index) => (
              <Button
                key={`advance-search-agegroup-${index}`}
                className={`${ageGroup.includes(toTitleCase(item.key)) ? "modal-btn-fill" : ""}`}
                onClick={() =>
                  handleMultipleOptionClick(ageGroup, setAgeGroup, toTitleCase(item.key))
                }
                disabled={index !== 0}
              >
                {toTitleCase(item.key)}
              </Button>
            ))}
          </div>
        </div>
      </div>

      <div className="search-modal-row hr">
        <div className="search-modal-group">
        <h3 className="search-title">Locations</h3>
          <div className="search-modal-btn-group">
            {searchParams.Locations.map((item, index) => (
              <Button
                key={`advance-search-locations-${index}`}
                className={`${
                  item.toLowerCase() === location.toLowerCase() ? "modal-btn-fill" : ""
                }`}
                disabled={index !== 0}
                onClick={() => handleSingleOptionClick(toTitleCase(item), setLocation)}
              >
                {item}
              </Button>
            ))}
          </div>
        </div>

        <div className="search-modal-group">
        <h3 className="search-title">Interventions</h3>
          <div className="search-modal-btn-group">
            {searchParams.Interventions.data.map((item, index) => (
              <Button
                key={`advance-search-interventions-${index}`}
                className={`${
                  interventions.includes(toTitleCase(item.key)) ? "modal-btn-fill" : ""
                }`}
                disabled={index !== 0}
                onClick={() =>
                  handleMultipleOptionClick(interventions, setInterventions, toTitleCase(item.key))
                }
              >
                {toTitleCase(item.key)}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};
