import React from "react";
import { AWSServiceConfiguration } from "../configuration";
import { DashboardConfigurations } from "./DashboardConfigurations";

export const EnterpsieAdminConfigurationsContainer = () => {
  return (
    <div className="mail-service-config autonomizeModal">
      <AWSServiceConfiguration />
      <DashboardConfigurations />
    </div>
  );
};
