import React from "react";
import { Entities, EvidencePageResponseInfo } from "../../../api";
import { CATEGORY_COLOR_MAPPING } from ".";
import "./evidence-content.scss";

interface Props {
  pageInfo: EvidencePageResponseInfo;
}
interface GroupByCategories {
  [key: string]: string[];
}

const groupByCategories = (entities: Partial<Entities>[], key: "Type" | "Category") => {
  const map: GroupByCategories = {};
  entities.forEach((item) => {
    const mapKey = item[key];
    if (mapKey && item.Text) {
      const collection = map[mapKey];
      if (!collection) {
        map[mapKey] = [item.Text];
      } else {
        collection.push(item.Text);
      }
    }
  });
  return map;
};

export const EvidenceContentMedicalEntities = ({ pageInfo }: Props) => {
  const list = groupByCategories(pageInfo.medicalEntities[0].Entities, "Category");
  return (
    <>
      <div className="e-paragraph">
        <div className="e-content-head name">{pageInfo.heading}</div>
        {Object.keys(list).map((key, i) => {
          const values = list[key];
          const color = CATEGORY_COLOR_MAPPING[key];
          return (
            <div key={`medical-entities-category-${i}`}>
              <p className="e-content-subtitle">{key.split("_").join(" ")}</p>
              <div className="flex ac e-content-chips">
                {values &&
                  values.map((item, index) => (
                    <p
                      key={`medical-entities-category-record-${index}`}
                      className="e-content-chip"
                      style={{ color: color }}
                    >
                      {item}
                    </p>
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const EvidenceContentNormalEntities = ({ pageInfo }: Props) => {
  const list = groupByCategories(pageInfo.normalEntities.ResultList[1].Entities, "Type");

  return (
    <>
      <div className="e-paragraph">
        <div className="e-content-head name">{pageInfo.heading}</div>
        {Object.keys(list).map((key, i) => {
          const values = list[key];
          return (
            <div key={`evidence-viewer-normal-entities-${i}`}>
              <p className="e-content-subtitle">{key}</p>
              <div className="flex ac e-content-chips">
                {values &&
                  values.map((item, index) => (
                    <p key={`normal-entities-category-record-${index}`} className="e-content-chip">
                      {item}
                    </p>
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
