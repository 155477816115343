import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Checkbox } from "antd";
import "../service-config.scss";
import { useForm } from "antd/es/form/Form";
import { ConfigurationObj, ConfigurationService } from "../../../api";
import { openNotificationWithIcon } from "../../../utils";
import { MAIL_SERVICE_CONFIGURATION_KEYS } from "./constant";
import { MandatoryFieldsText } from "../../../components";

interface Props {
  mailService: string;
  clientId: number;
  configuration: ConfigurationObj[];
  setConfiguration: Dispatch<SetStateAction<ConfigurationObj[]>>;
}

export const SMTPForm = ({ mailService, clientId, configuration, setConfiguration }: Props) => {
  const [form] = useForm();
  const [disableSave, setDisableSave] = useState(true);
  const [isSMTPSecure, setIsSMTPSecure] = useState(true);
  const [isStartTlsActive, setIsStartTlsActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ [MAIL_SERVICE_CONFIGURATION_KEYS.smtpSecure]: isSMTPSecure.toString() });
    form.setFieldsValue({
      [MAIL_SERVICE_CONFIGURATION_KEYS.startTls]: isStartTlsActive.toString(),
    });
  }, [form, isSMTPSecure, isStartTlsActive]);

  const updateForm = useCallback(
    (data: ConfigurationObj[]) => {
      const key = [
        MAIL_SERVICE_CONFIGURATION_KEYS.smtpHost,
        MAIL_SERVICE_CONFIGURATION_KEYS.smtpPort,
        MAIL_SERVICE_CONFIGURATION_KEYS.smtpUser,
        MAIL_SERVICE_CONFIGURATION_KEYS.smtpPassword,
        MAIL_SERVICE_CONFIGURATION_KEYS.senderEmail,
        MAIL_SERVICE_CONFIGURATION_KEYS.adminEmail,
      ];
      const obj: { [key: string]: string } = {};
      key.forEach((item) => {
        const index = data.findIndex((config) => config.field_key === item);
        if (index !== -1) {
          const content = data[index].field_content;
          obj[data[index].field_key] = Array.isArray(content) ? "" : content;
        }
      });
      form.setFieldsValue({ ...obj });
      const smtpSecureIndex = data.findIndex(
        (config) => config.field_key === MAIL_SERVICE_CONFIGURATION_KEYS.smtpSecure
      );
      if (smtpSecureIndex !== -1)
        setIsSMTPSecure(data[smtpSecureIndex].field_content ? true : false);
      const startTlsIndex = data.findIndex(
        (config) => config.field_key === MAIL_SERVICE_CONFIGURATION_KEYS.startTls
      );
      if (startTlsIndex !== -1)
        setIsStartTlsActive(data[startTlsIndex].field_content ? true : false);
    },
    [form]
  );

  useEffect(() => {
    updateForm(configuration);
  }, [configuration, updateForm]);

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };
  const handleSubmit = async (e: any) => {
    if (disableSave) return;
    setLoading(true);
    const smtpHost = e.target[0].value;
    const smtpPort = e.target[1].value;
    const smtpUser = e.target[2].value;
    const smtpPassword = e.target[3].value;
    const senderEmail = e.target[4].value;
    const adminEmail = e.target[5].value;
    try {
      const reqObj: ConfigurationObj[] = [
        {
          field_content: mailService,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.mailService,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.mailService.split("_").join(" "),
        },
        {
          field_content: smtpHost,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.smtpHost,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.smtpHost.split("_").join(" "),
        },
        {
          field_content: smtpPort,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.smtpPort,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.smtpPort.split("_").join(" "),
        },
        {
          field_content: smtpUser,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.smtpUser,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.smtpUser.split("_").join(" "),
        },
        {
          field_content: smtpPassword,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.smtpPassword,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.smtpPassword.split("_").join(" "),
        },
        {
          field_content: senderEmail,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.senderEmail,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.senderEmail.split("_").join(" "),
        },
        {
          field_content: adminEmail,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.adminEmail,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.adminEmail.split("_").join(" "),
        },
        {
          field_content: isSMTPSecure,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.smtpSecure,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.smtpSecure.split("_").join(" "),
        },
        {
          field_content: isStartTlsActive,
          field_key: MAIL_SERVICE_CONFIGURATION_KEYS.startTls,
          field_name: MAIL_SERVICE_CONFIGURATION_KEYS.startTls.split("_").join(" "),
        },
      ];
      const { data } = await ConfigurationService.updateConfiguration(clientId, reqObj);
      setConfiguration([...new Set([...data.data, ...configuration])]);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      layout="vertical"
      className="autonomizeModal form-grid"
      form={form}
      onSubmitCapture={handleSubmit}
      onFieldsChange={handleFormChange}
      colon={false}
      requiredMark={false}
    >
      <Form.Item
        name={MAIL_SERVICE_CONFIGURATION_KEYS.smtpHost}
        label="SMTP Host"
        rules={[{ required: true, message: "This field cannot be blank" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={MAIL_SERVICE_CONFIGURATION_KEYS.smtpPort}
        label="SMTP Port"
        rules={[{ required: true, message: "This field cannot be blank" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={MAIL_SERVICE_CONFIGURATION_KEYS.smtpUser}
        label="SMTP User"
        rules={[{ required: true, message: "This field cannot be blank" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={MAIL_SERVICE_CONFIGURATION_KEYS.smtpPassword}
        label="SMTP Password"
        rules={[{ required: true, message: "This field cannot be blank" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={MAIL_SERVICE_CONFIGURATION_KEYS.senderEmail}
        label="Sender Email"
        rules={[
          { required: true, message: "This field cannot be blank" },
          { type: "email", message: "Please enter valid email" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={MAIL_SERVICE_CONFIGURATION_KEYS.adminEmail}
        label="Contact Email Admin"
        rules={[
          { required: true, message: "This field cannot be blank" },
          { type: "email", message: "Please enter valid email" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={MAIL_SERVICE_CONFIGURATION_KEYS.smtpSecure}
        label=""
        rules={[{ required: true, message: "This field cannot be blank" }]}
      >
        <Checkbox onChange={(e) => setIsSMTPSecure(e.target.checked)} checked={isSMTPSecure}>
          SMTP Secure
        </Checkbox>
      </Form.Item>
      <Form.Item
        name={MAIL_SERVICE_CONFIGURATION_KEYS.startTls}
        label=""
        rules={[{ required: true, message: "This field cannot be blank" }]}
      >
        <Checkbox
          onChange={(e) => setIsStartTlsActive(e.target.checked)}
          checked={isStartTlsActive}
        >
          Start TLS
        </Checkbox>
      </Form.Item>
      <Form.Item className="mandatory-text no-grid-row save-btn">
        <MandatoryFieldsText />

        <Button htmlType="submit" className="outline" disabled={disableSave} loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
