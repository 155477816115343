import React from "react";
import { Row, Col } from "antd";
import {
  ConditionsCard,
  CurrentInfectiousDiseaseCard,
  DemographicsCard,
  FileListCard,
  ImmunizationHistoryCard,
  PatientMedicationHistory,
  SocialHistoryCard,
  PastTreatmentProceduresCard,
  RenalCareInsightsCard,
} from "./patient-insight-tiles";
import { IPatient } from "../../../interfaces";
import { getThresholdConfidenceScore } from "../../../utils";
import { PatientProjects } from "../../../api";

interface Props {
  patientInfo: IPatient;
  selectedProject?: PatientProjects;
}

export const PatientInsightTiles = ({ patientInfo, selectedProject }: Props) => {
  const {
    patientId,
    conditions,
    demographics,
    family_medical_history,
    fileList,
    immunization_history,
    infectious_diseases,
    lifestyle,
    medication_history,
    threshold_confidence_score,
    biomarkers,
    risk_factor,
    past_history,
    social_history,
    patient_name,
    dob,
    cmsResult,
    labResult,
  } = patientInfo;

  const threshold = getThresholdConfidenceScore(threshold_confidence_score);

  const checkRentalCareInsightsOrder =
    selectedProject?.Name === "2728 Forms" || selectedProject?.Name === "Renal Care Labs Insights "
      ? true
      : false;

  const getRentalCareInsightsCard = () => {
    return (
      <Col className="gutter-row" span={24}>
        <RenalCareInsightsCard
          patientName={patient_name}
          dob={dob}
          patientId={patientId}
          threshold={threshold}
          gender={demographics.sex}
          cmsValues={cmsResult}
          labValues={labResult}
        />
      </Col>
    );
  };

  return (
    <>
      {/* <h4 className="tile-heading">Subject Insights</h4> */}
      <div className="project-specific m-t">
        {/* {project_specific && (
        <div className="tp-border project-specific-title">
          <p>Patient Insights</p>
        </div>
      )} */}
        <Row gutter={[40, 40]}>
          {/* <Col className="gutter-row" span={9}>
            {demographics && <DemographicsCard demographics={demographics} />}
          </Col> */}
          {/* <Col className="gutter-row" span={8}>
          {biomarkers && (
            <BloodBasedBiomarkerCard
              data={biomarkers}
              patientId={patientId}
              threshold={threshold}
            />
          )}
        </Col> */}
          {/* <Col className="gutter-row" span={8}>
          {risk_factor && (
            <RiskFactorCard riskFactors={risk_factor} patientId={patientId} threshold={threshold} />
          )}
        </Col> */}

          {checkRentalCareInsightsOrder && getRentalCareInsightsCard()}
          <Col className="gutter-row" span={12}>
            {conditions && (
              <ConditionsCard conditions={conditions} patientId={patientId} threshold={threshold} />
            )}
          </Col>
          <Col className="infectious gutter-row" span={12}>
            {infectious_diseases && (
              <CurrentInfectiousDiseaseCard
                infecitous_disease={infectious_diseases}
                patientId={patientId}
                threshold={threshold}
              />
            )}
          </Col>
          {!checkRentalCareInsightsOrder && getRentalCareInsightsCard()}
          {/* <Col className="gutter-row" span={9}>
            {fileList && <FileListCard fileList={fileList} />}
          </Col> */}
          <Col className="gutter-row" span={24}>
            {medication_history && (
              <PatientMedicationHistory
                patientId={patientId}
                medication_history={medication_history}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {immunization_history && (
              <ImmunizationHistoryCard data={immunization_history} patientId={patientId} />
            )}
          </Col>

          {/* <Col className="gutter-row" span={12}>
          {lifestyle && <LifestyleCard lifestyle={lifestyle} patientId={patientId} />}
        </Col> */}
          {/* <Col className="gutter-row" span={12}>
          {family_medical_history && (
            <PatientFamilyMedicalHistory
              patientId={patientId}
              family_medical_history={family_medical_history}
            />
          )}
        </Col> */}
          {/* <Col className="gutter-row" span={6}>
          {medical_history && (
            <PatientMedicalHistory medical_history={medical_history} patientId={patientId} />
          )}
        </Col> */}
          <Col className="gutter-row" span={12}>
            {past_history && (
              <PastTreatmentProceduresCard
                pastTreatmentProcedures={past_history}
                patientId={patientId}
                threshold={threshold}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {social_history && (
              <SocialHistoryCard
                socialHistory={social_history}
                patientId={patientId}
                threshold={threshold}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
