import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row, Table } from "antd";
import { CMSValues, Condition, LabValueResult } from "../../../../interfaces";
import "../patientprofile.scss";
import {
    EVIDENCE_VIEWER_URL,
    PatientCardWrapper,
} from "../tile-helper-components";
import { PATIENT_INSIGHT_TILES } from "..";
import { INSIGHT_TYPE } from "../../../evidence-viewer/evidence-viewer-container";
import {
    CMS2728InsightsCard,
    PatientDetailsCard,
    LabValueResultsCard
} from "./";


interface Props {
    threshold: number;
    patientId: number;
    patientName: string;
    dob: string;
    gender: string;
    cmsValues: CMSValues[];
    labValues: LabValueResult[];
}

export const RenalCareInsightsCard = ({ patientId, threshold, patientName, dob, gender, cmsValues, labValues }: Props) => {

    const navigate = useNavigate();

    const handleSearchNavigate = (str: string) => {
        navigate(
            `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`
        );
    };

    return (
        <>
            <PatientCardWrapper title={PATIENT_INSIGHT_TILES.renalCareInsights}>
                <Row gutter={[40, 40]}>
                    <Col className="gutter-row" span={8}>
                        <PatientDetailsCard patientId={patientId} threshold={threshold} patientName={patientName} dob={dob} gender={gender}/>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <LabValueResultsCard patientId={patientId} threshold={threshold} labValues={labValues}/>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <CMS2728InsightsCard patientId={patientId} threshold={threshold} cmsValues={cmsValues}/>
                    </Col>
                </Row>
            </PatientCardWrapper>
        </>
    );
};
