import { useCallback, useState } from "react";
import { cohortService } from "../api/cohort";

export interface IFetchCohortPatientObj {
  limit: number;
  offset: number;
  cohortId: string;
}

export const useFetchCohortPatients = () => {
  const [loading, setLoading] = useState(false);
  const [cohortPatients, setCohortPatients] = useState([] as ICohortPatient[]);
  const [total, setTotal] = useState(0);

  const fetchCohortPatients = useCallback(async (obj: IFetchCohortPatientObj) => {
    setLoading(true);
    try {
      const qs = `${obj.cohortId}/${obj.offset}/${obj.limit}`;
      const { data } = await cohortService.getCohortPatientList(qs);
      setCohortPatients([...data.data]);
      setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    cohortPatientsLoading: loading,
    fetchCohortPatients,
    cohortPatients,
    totalCohortPatients: total,
    setCohortPatientLoading: setLoading,
  };
};
