import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { AppRoutes } from "./navigation/AppRoutes";
import { Modal } from "./containers/modals/Modal";
import createStore from "./store";
import "antd/dist/antd.min.css";
import "./assets/sass/main.scss";
import { ThemeContext, useThemeProvider } from "./hooks/useTheme";
import "./App.css";

export const store = createStore();
const persistor = persistStore(store);

const ScrollWrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const printScreenKeys = [
  "PrintScreen",
  "printScreen",
  "F10",
  "f10",
  "F12",
  "f12",
  "Ctrl+PrintScreen",
  "Ctrl+printScreen",
  "Cmd+Shift+3",
];

function App() {
  const { theme, toggleTheme } = useThemeProvider();

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (printScreenKeys.includes(event.key)) {
      event.preventDefault();
      document.querySelector(".screenshot-blocker")?.classList.add("active");
      setTimeout(() => {
        document.querySelector(".screenshot-blocker")?.classList.remove("active");
        alert("Screenshots are disabled in this application.");
      }, 1000); // Remove the active class after 1 second
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <ScrollWrapper>
              <ThemeContext.Provider value={{ theme, toggleTheme }}>
                <Modal />
                <AppRoutes />
              </ThemeContext.Provider>
            </ScrollWrapper>
          </BrowserRouter>
        </PersistGate>
      </Provider>
      <div className="screenshot-blocker"></div>
    </div>
  );
}

export default App;
