import { apiClient } from ".";

interface KeyStatus {
  key: string;
  status: string;
}

export interface AdvanceSearchParams {
  Type: KeyStatus[];
  Diseases: {
    searchKey: string;
    data: string[];
  };
  Gender: KeyStatus[];
  Locations: string[];
  ageGroup: {
    searchKey: string;
    data: KeyStatus[];
  };
  Interventions: {
    searchKey: string;
    data: KeyStatus[];
  };
}

export interface GetSearchResultsRequest {
  search: string[];
  ageGroup: string;
  gender: string;
  fileName: string;
  patient_id: string;
  type: string;
}

export interface SearchResultResponseData {
  fileName: string;
  occurences: number;
  mrn: string;
  patientId: number;
  patientName: string;
  score: string;
  fileId: string;
}

export interface SearchResultResponse {
  data: SearchResultResponseData[];
  searchData: {
    body: string[];
  };
  lastSearch: RecentAndSaveSearch;
}

export interface RecentAndSaveSearch {
  count: number;
  featured: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
  query: string[];
}
interface RecentAndSavedSearchResponse {
  data: RecentAndSaveSearch[];
}

const getAdvanceSearchParams = async () => {
  const res = await apiClient.post<AdvanceSearchParams>("search/filters", {});
  return res.data;
};

const getSearchResult = async (requestObject: Partial<GetSearchResultsRequest>) => {
  const res = await apiClient.post<SearchResultResponse>(`/search/v2`, requestObject);
  return res;
};

const getRecent = async () => {
  const res = await apiClient.get<RecentAndSavedSearchResponse>("/search/history");
  return res;
};

const getSaved = async () => {
  const res = await apiClient.get<RecentAndSavedSearchResponse>("/search/featured");
  return res;
};

const bookmarkSearch = async (id: number, val: boolean) => {
  const obj = { featured: val };
  const res = await apiClient.put<{ data: RecentAndSaveSearch }>(`/search/history/${id}`, obj);
  return res;
};

export const searchService = {
  getAdvanceSearchParams,
  getSearchResult,
  bookmarkSearch,
  getRecent,
  getSaved,
};
