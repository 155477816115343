import React from "react";
import { IHEDISMeasures } from "../../../../../interfaces";
import { HEDISMeasureInsightTileWrapper } from "./HEDISMeasureInsightTileWrapper";
import { HEDIS_MEASURE_INSIGHTS_TILES } from "../..";
import { HedisMedicalEntites } from "./HedisMedicalEntites";
import { HedisKeywords } from "./HedisKeywords";
import { HedisQA } from "./HedisQA";

interface Props {
  comprehensiveDiabetesCare: IHEDISMeasures;
  patientId: number;
}

export const ComprehensiveDiabetesCareTile = ({ comprehensiveDiabetesCare, patientId }: Props) => {
  return (
    <>
      <HEDISMeasureInsightTileWrapper title={HEDIS_MEASURE_INSIGHTS_TILES.HEDIS_CDC}>
        <div className="hedis-measure">
          <HedisMedicalEntites
            patientId={patientId}
            mcomprehend={comprehensiveDiabetesCare.mcomprehend}
          />
          <div className="hedis-measure-container-right">
            <HedisKeywords keyword={comprehensiveDiabetesCare.keyword} />
            <HedisQA qa={comprehensiveDiabetesCare.qa} />
          </div>
        </div>
      </HEDISMeasureInsightTileWrapper>
    </>
  );
};
