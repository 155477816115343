import React from "react";
import { HEDIS_MEASURE, HEDIS_MEASURE_INSIGHTS_TILES } from "..";

export const HEDISMeasureInsightsQuickLinks = () => {
  return (
    <div className="quick-links">
      <ul className="quick-links-list mb-0 flex">
        {Object.values(HEDIS_MEASURE_INSIGHTS_TILES).map((tile, index) => (
          <li key={`hedis-measure-insights-tile-link-${index}`}>
            <a href={`#${tile.toLocaleLowerCase().split(" ").join("_")}`}>
              {tile.split(HEDIS_MEASURE)[1]}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
