import React, { useCallback } from "react";
import { UploadFile } from "antd";
import { projectService } from "../../../api";
import { FileUploader } from "../../../components";

interface Props {
  projectId: number;
}

export const UploadProjectDocuments = ({ projectId }: Props) => {
  const isFileNameValid = useCallback((fileName: string) => {
    const pattern = /^\d+_.*/;
    return pattern.test(fileName);
  }, []);

  const onFileUpload = useCallback(
    async (file: UploadFile<any>) => {
      const { data } = await projectService.getS3UploadURL(projectId, file.name);
      const res = await projectService.uploadFiles(data.response, file);
    },
    [projectId]
  );

  return (
    <FileUploader
      onSubmit={onFileUpload}
      validateFileName={isFileNameValid}
      validateFileNameErrorMessage={
        "Invalid file name format. Rename the file to start with a digit(s), followed by an underscore, and any characters"
      }
    />
  );
};
