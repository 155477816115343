import React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import { LabValueResult } from "../../../../interfaces";
import "../patientprofile.scss";
import {
    CONFIDENCE_SCORE_INFO,
    EVIDENCE_VIEWER_URL,
    PatientCardWrapper,
    ViewMoreResults,
    NoResultsFoundMessage,
} from "../tile-helper-components";
import { scoreToPercentage } from "../../../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PATIENT_INSIGHT_TILES } from "..";

interface DataType {
    test: string;
    value: string;
    cpt_code: string;
    score: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: "Test",
        dataIndex: "test",
        key: "test",
    },
    {
        title: "Value",
        dataIndex: "value",
        key: "value",
    },
    {
        title: "CPT Code",
        dataIndex: "cpt_code",
        key: "cpt_code",
    },
    {
        title: "Confidence Score",
        dataIndex: "score",
        key: "score",
    },
];

interface Props {
    threshold: number;
    patientId: number;
    labValues: LabValueResult[];
}

export const LabValueResultsCard = ({ patientId, threshold, labValues }: Props) => {
    if (!labValues || labValues.length == 0) {
        labValues = [];
    }
    const sorted = labValues.sort((a, b) => b.confidence_score - a.confidence_score);
    const aboveEighty = sorted.filter((a) => a.confidence_score >= threshold / 100);
    const belowEighty = sorted.filter((a) => a.confidence_score < threshold / 100);

    const navigate = useNavigate();

    const handleSearchNavigate = (str: string) => {
        navigate(
            `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&checkLegend=false`
        );
    };

    return (
        <>
            <PatientCardWrapper title="Lab Value Results">
                <div className="blue flex patient-card tiles-max-height-table">
                    {aboveEighty.length > 0 ? (
                        <Table
                            columns={columns}
                            dataSource={
                                aboveEighty
                                    ? aboveEighty.map((item, index) => ({
                                        test: item.test,
                                        value: item.value,
                                        cpt_code: item.cpt_code,
                                        score: scoreToPercentage(item.confidence_score),
                                    }))
                                    : []
                            }
                            pagination={false}
                            className="cmn-table"
                            rowClassName={"cursor-pointer"}
                            onRow={(record) => ({ onClick: () => handleSearchNavigate(record.test) })}
                        />
                    ) : (
                        <NoResultsFoundMessage
                            aboveEightyLength={aboveEighty.length}
                            belowEightyLength={belowEighty.length}
                            threshold={threshold}
                        />
                    )}
                    <ViewMoreResults
                        searchParanms={{
                            search: [...new Set(belowEighty.map((a) => a.test))].join(","),
                            patient_id: patientId,
                        }}
                        belowEightyLength={belowEighty.length}
                        threshold={threshold}
                    />
                </div>
            </PatientCardWrapper>
        </>
    );
};
