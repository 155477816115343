import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User, usersService } from "../api";
import { RootState, setUserList } from "../store";
import { useUser } from "./useUser";

export const useFetchUserList = () => {
  const dispatch = useDispatch();
  const { clientId } = useUser();
  const { list } = useSelector((state: RootState) => state.data.users.users);
  const [loading, setLoading] = useState(false);

  const fetchUsers = useCallback(
    async (id?: number) => {
      setLoading(true);
      const finalClientId = id ? id : clientId;
      const { data } = await usersService.getAll(finalClientId);
      const { data: userData } = data;
      const users = [] as User[];
      userData.forEach((obj) => {
        Object.entries(obj).map(([key, value]) => {
          if (typeof value !== "string") {
            users.push(...value);
          }
        });
      });
      dispatch(setUserList(users));
      setLoading(false);
    },
    [clientId, dispatch]
  );

  return {
    loading,
    fetchUsers,
    usersList: list,
  };
};
