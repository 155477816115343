import React, { useEffect, useState } from "react";
import { Card, Form } from "antd";
import "../service-config.scss";
import { DropdownOption } from "../../../interfaces";
import { Dropdown } from "../../../components";
import { SMTPForm } from "./SMTPForm";
import { SendGridForm } from "./SendGridForm";
import { ConfigurationObj, ConfigurationService } from "../../../api";
import { MAIL_SERVICE_CONFIGURATION_KEYS } from "./constant";

const MAIL_SERVICE_OPTIONS: DropdownOption[] = ["SMTP", "SendGrid"].map((item, index) => ({
  value: item,
  id: index,
}));

interface Props {
  clientId: number;
}

export const MailServiceConfiguration = ({ clientId }: Props) => {
  const [mailServiceSelectedOption, setmailServiceSelectedOption] = useState(
    MAIL_SERVICE_OPTIONS[0]
  );
  const [configuration, setConfiguration] = useState([] as ConfigurationObj[]);
  const [loading, setLoading] = useState(false);

  const onMailServiceChange = (value: string, option: DropdownOption) =>
    setmailServiceSelectedOption(option);

  useEffect(() => {
    const fetchConfigurations = async () => {
      setLoading(true);
      const { data } = await ConfigurationService.getConfigurations(clientId);
      setConfiguration(data.data);
      // check if mail service is already setup
      const index = data.data.findIndex(
        (config) => config.field_key === MAIL_SERVICE_CONFIGURATION_KEYS.mailService
      );
      if (index !== -1) {
        // check if mail service is sendgrid
        const isSendgrid = data.data[index].field_content === MAIL_SERVICE_OPTIONS[1].value;
        if (isSendgrid) setmailServiceSelectedOption(MAIL_SERVICE_OPTIONS[1]);
      }
      setLoading(false);
    };
    if (clientId) fetchConfigurations();
  }, [clientId]);

  return (
    <div className="mail-service-config grid-no">
      <p className="basic-detail-title">Email Service Configuration</p>
      <Card className="ant-card w-full" loading={loading}>
        <Form.Item className="w-45 font-medium" label="Mail Service">
          <Dropdown
            value={mailServiceSelectedOption.value}
            loading={false}
            onSelect={onMailServiceChange}
            options={MAIL_SERVICE_OPTIONS}
          />
        </Form.Item>
        {mailServiceSelectedOption.value === MAIL_SERVICE_OPTIONS[0].value ? (
          <SMTPForm
            mailService={mailServiceSelectedOption.value}
            clientId={clientId}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ) : (
          <SendGridForm
            mailService={mailServiceSelectedOption.value}
            clientId={clientId}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        )}
      </Card>
    </div>
  );
};
