import React from "react";
import { VitalSignsAndPhysicalMeasurements, DemographicsCard } from "./patient-insight-summary";
import { IPatient } from "../../../interfaces";

interface Props {
  patientInfo: IPatient;
}

export const Summary = ({ patientInfo }: Props) => {
  const { demographics } = patientInfo;
  const displayVitalSigns = process.env.REACT_APP_DISPLAY_VITAL_SIGNS === "true";
  return (
    <div>
      {demographics && <DemographicsCard demographics={demographics} />}
      {displayVitalSigns && <VitalSignsAndPhysicalMeasurements />}
    </div>
  );
};
