import { Dispatch } from "redux";
import { connect } from "react-redux";
import AddEditUser from "./AddEditUser";
import { closeModal, RootState, setUserEditDelete } from "../../../store";
import { MODAL_TYPE, PAGE_URL } from "../../../utils";
import { User } from "../../../api";

type StateProps = {
  visibility: boolean;
  isEdit: boolean;
  editObj: User;
  clientId: number;
  userRoleCode: string;
};
type DispatchProps = {
  handleClose: () => void;
};
export type AddEditUserProps = StateProps & DispatchProps;

const getClientId = () => {
  if (window.location.pathname.includes(PAGE_URL.CLIENT_DETAIL)) {
    const pathnameSplit = window.location.pathname.split("/");
    const id = pathnameSplit[pathnameSplit.length - 1];
    return Number(id);
  } else return null;
};

const mapStateToProps = (state: RootState): StateProps => {
  const isEdit = state.data.users.users.isEdit;
  const id = state.data.users.users.id;
  let clientId = getClientId();
  if (!clientId) clientId = state.user.user.clientId;
  let editObj = {} as User;
  if (isEdit && id !== -1) {
    const obj = state.data.users.users.list.find((item) => item.id === id);
    if (obj) editObj = obj;
  }
  return {
    visibility: state.ui.modal[MODAL_TYPE.ADD_EDIT_USER],
    isEdit,
    editObj,
    clientId,
    userRoleCode: state.user.user.roles.RoleCode,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(setUserEditDelete({ id: -1, isEdit: false }));
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_USER));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
