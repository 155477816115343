import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../api";
import { Project } from "../../interfaces";
import {
  Client,
  ClientExtended,
  DataSlice,
  ProjectExtended,
  UserExtended,
  UserWithProject,
  UserWithProjectExtended,
} from "./types";

const clientInitialState: ClientExtended = {
  id: -1,
  isEdit: false,
  list: [] as Client[],
};
const projectInitialState: ProjectExtended = {
  id: -1,
  isEdit: false,
  list: [] as Project[],
};
const userExtendedInitialState: UserExtended = {
  id: -1,
  isEdit: false,
  list: [] as User[],
  visualName: "",
};
const userWithProjectExtendedInitialState: UserWithProjectExtended = {
  id: -1,
  isEdit: false,
  list: [] as UserWithProject[],
};

export const dataInitialState: DataSlice = {
  clients: clientInitialState,
  projects: projectInitialState,
  users: {
    users: userExtendedInitialState,
    usersWithProject: userWithProjectExtendedInitialState,
  },
};

export const dataSlice = createSlice({
  name: "data",
  initialState: dataInitialState,
  reducers: {
    setUserList: (state: DataSlice, { payload }: PayloadAction<User[]>) => {
      state.users.users.list = [...payload];
    },
    addNewUser: (state: DataSlice, { payload }: PayloadAction<{ user: User }>) => {
      state.users.users.list = [...state.users.users.list, payload.user];
    },
    updateUser: (state: DataSlice, { payload }: PayloadAction<{ user: User }>) => {
      state.users.users.list = state.users.users.list.map((item) => {
        if (item.id === payload.user.id) return { ...payload.user };
        else return { ...item };
      });
    },
    deleteUser: (state: DataSlice, { payload }: PayloadAction<{ id: number }>) => {
      const index = state.users.users.list.findIndex((item) => item.id === payload.id);
      if (index !== -1)
        state.users.users.list = [
          ...state.users.users.list.slice(0, index),
          ...state.users.users.list.slice(index + 1),
        ];
    },
    disableEnableUser: (
      state: DataSlice,
      { payload }: PayloadAction<{ userId: number; status: boolean }>
    ) => {
      state.users.users.list = state.users.users.list.map((item) => {
        if (item.id === payload.userId) return { ...item, status: payload.status };
        else return { ...item };
      });
    },
    setUserEditDelete: (
      state: DataSlice,
      { payload }: PayloadAction<{ id: number; isEdit: boolean }>
    ) => {
      state.users.users.isEdit = payload.isEdit;
      state.users.users.id = payload.id;
    },
    setUserWithProjects: (state: DataSlice, { payload }: PayloadAction<UserWithProject[]>) => {
      state.users.usersWithProject.list = [...payload];
    },
    setUserWithProjectsEdit: (state: DataSlice, { payload }: PayloadAction<number>) => {
      state.users.usersWithProject.id = payload;
    },
    setProjects: (state: DataSlice, { payload }: PayloadAction<Project[]>) => {
      state.projects.list = [...payload];
    },
    addProject: (state: DataSlice, { payload }: PayloadAction<Project>) => {
      state.projects.list = [...state.projects.list, payload];
    },
    updateProject: (state: DataSlice, { payload }: PayloadAction<Project>) => {
      state.projects.list = state.projects.list.map((item) => {
        if (item.Id === payload.Id) return { ...payload };
        else return { ...item };
      });
    },
    deleteProject: (state: DataSlice, { payload }: PayloadAction<number>) => {
      const index = state.projects.list.findIndex((item) => item.Id === payload);
      if (index !== -1)
        state.projects.list = [
          ...state.projects.list.slice(0, index),
          ...state.projects.list.slice(index + 1),
        ];
    },
    setProjectEditDelete: (
      state: DataSlice,
      { payload }: PayloadAction<{ id: number; isEdit: boolean }>
    ) => {
      state.projects.isEdit = payload.isEdit;
      state.projects.id = payload.id;
    },
    setClients: (state: DataSlice, { payload }: PayloadAction<Client[]>) => {
      state.clients.list = [...payload];
    },
    addNewClient: (state: DataSlice, { payload }: PayloadAction<Client>) => {
      state.clients.list = [...state.clients.list, payload];
    },
    updateClient: (state: DataSlice, { payload }: PayloadAction<Client>) => {
      state.clients.list = state.clients.list.map((item) => {
        if (item.id === payload.id) return { ...payload };
        else return { ...item };
      });
    },
    deleteClient: (state: DataSlice, { payload }: PayloadAction<number>) => {
      const index = state.clients.list.findIndex((item) => item.id === payload);
      if (index !== -1)
        state.clients.list = [
          ...state.clients.list.slice(0, index),
          ...state.clients.list.slice(index + 1),
        ];
    },
    setClientEditDelete: (
      state: DataSlice,
      { payload }: PayloadAction<{ id: number; isEdit: boolean }>
    ) => {
      state.clients.isEdit = payload.isEdit;
      state.clients.id = payload.id;
    },
  },
});

export const {
  setUserList,
  addNewUser,
  updateUser,
  deleteUser,
  setUserEditDelete,
  disableEnableUser,
  setClients,
  addNewClient,
  deleteClient,
  updateClient,
  setClientEditDelete,
  setProjects,
  addProject,
  updateProject,
  deleteProject,
  setProjectEditDelete,
  setUserWithProjects,
  setUserWithProjectsEdit,
} = dataSlice.actions;

export default dataSlice.reducer;
