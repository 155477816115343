import React, { useEffect } from "react";
import { Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { PatientListFilter } from ".";
import { FetchPatientsObj, useFetchPatients, usePatientFilter } from "../../../hooks";
import { getLocalDateTimeStringFromISO, PAGE_URL } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppPagination } from "../../../components";

interface Props {
  projectId?: number;
}

interface PatientListDataType {
  key: React.Key;
  patientName: string;
  patientId: number;
  uploadedDate: string;
  projectName: string;
  status: string;
  statusColor: string;
  reviewedDate: string;
  mrn: string;
  projectId: number;
}

const getColumns = (): ColumnsType<PatientListDataType> => [
  {
    title: "Subject Name",
    dataIndex: "patientName",
    sorter: true,
  },
  {
    title: "Subject ID",
    dataIndex: "mrn",
  },
  {
    title: "Project Name",
    dataIndex: "projectName",
    sorter: true,
  },
  {
    title: "Uploaded Date",
    dataIndex: "uploadedDate",
    sorter: true,
  },
  {
    title: "Reviewed Date",
    dataIndex: "reviewedDate",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value, record) => <div style={{ color: record.statusColor }}>{value}</div>,
  },
];

export const AssociatedPatientsList = ({ projectId }: Props) => {
  const navigate = useNavigate();
  const { getPatientFilterObject, paginationObj, patientStatusFilter, searchFilter, sortFilter } =
    usePatientFilter({
      onPatientsList: true,
    });
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { onPatientStatusChange, patientStatusLoading, patientStatusOptions } = patientStatusFilter;
  const { onSearchChange, searchKeyword } = searchFilter;
  const { setSort } = sortFilter;
  const { fetchPatients, patientsLoading, patients, totalPatients } = useFetchPatients();

  const patientsData: PatientListDataType[] = patients.map((patient, index) => ({
    key: `patient-list-${index}`,
    mrn: patient.Mrn,
    patientId: patient.Id,
    patientName: patient.Name,
    projectName: patient.projectName,
    projectId: patient.projectId,
    reviewedDate: getLocalDateTimeStringFromISO(patient.projectStatusUpdatedAt),
    status: patient.projectStatusName,
    statusColor: patient.projectStatusColorCode,
    uploadedDate: getLocalDateTimeStringFromISO(patient.CreatedAt),
  }));

  const onTableChange: TableProps<PatientListDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setSort(sorter);
  };

  const handleRowClick = (item: PatientListDataType) => {
    navigate(`${PAGE_URL.PATIENT_PROFILE}/${item.patientId}?projectId=${item.projectId}`);
  };

  useEffect(() => {
    const getPatients = async () => {
      let obj: FetchPatientsObj = { assigned: true };
      if (projectId) obj = { ...obj, projectId };
      obj = getPatientFilterObject(obj);
      await fetchPatients(obj);
    };
    getPatients();
  }, [fetchPatients, getPatientFilterObject, projectId]);

  return (
    <div className="patients-tab">
      <PatientListFilter
        onPatientsList
        {...{
          onSearchChange,
          searchKeyword,
          patientStatusLoading,
          patientStatusOptions,
          onPatientStatusChange,
        }}
      />
      <AppPagination
        {...{
          showSizeChanger: true,
          current: page,
          total: totalPatients,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />
      <Table
        className="basic-detail-content tb-scroll status-tag"
        rowClassName="cursor-pointer"
        columns={getColumns()}
        dataSource={patientsData}
        onChange={onTableChange}
        loading={patientsLoading}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record),
          };
        }}
        pagination={false}
      />
    </div>
  );
};
