import React from "react";
import { IHEDISMeasures } from "../../../../../interfaces";
import { HEDISMeasureInsightTileWrapper } from "./HEDISMeasureInsightTileWrapper";
import { HEDIS_MEASURE_INSIGHTS_TILES } from "../..";
import { HedisQA } from "./HedisQA";
import { HedisKeywords } from "./HedisKeywords";
import { HedisMedicalEntites } from "./HedisMedicalEntites";

interface Props {
  colorectalCancerScreening: IHEDISMeasures;
  patientId: number;
}

export const ColorectalCancerScreeningTile = ({ colorectalCancerScreening, patientId }: Props) => {
  return (
    <>
      <HEDISMeasureInsightTileWrapper title={HEDIS_MEASURE_INSIGHTS_TILES.HEDIS_COL}>
        <div className="hedis-measure">
          <HedisMedicalEntites
            patientId={patientId}
            mcomprehend={colorectalCancerScreening.mcomprehend}
          />
          <div className="hedis-measure-container-right">
            <HedisKeywords keyword={colorectalCancerScreening.keyword} />
            <HedisQA qa={colorectalCancerScreening.qa} />
          </div>
        </div>
      </HEDISMeasureInsightTileWrapper>
    </>
  );
};
