import { apiClient } from ".";
import { IPagination } from "../interfaces";

interface IUpdateCohortRequest {
  Name: string;
}

interface ICohortPatientListResponse {
  message: string;
  data: ICohortPatient[];
  pagination: IPagination;
}

interface ICohortListResponse {
  message: string;
  data: ICohort[];
  pagination: IPagination;
}

export interface ISaveCohortResponse {
  message: string;
  response: string;
}

interface IDeleteCohortPatientResponse {
  message: string;
  response: string;
}

interface IUpdateCohortResponse {
  message: string;
  response: string;
}

const getCohortList = async (queryString?: string) => {
  if (queryString) {
    return await apiClient.get<ICohortListResponse>(`/cohort/getCohortList/${queryString}`);
  }
  const res = await apiClient.get<ICohortListResponse>(`/cohort/getCohortList/0/1000`);
  return res;
};

const saveCohort = async (cohortName: string, patientId: number, cohortId?: number) => {
  let reqObject = {} as ISaveCohort;
  if (cohortId) {
    reqObject = {
      Cohort: { Name: cohortName },
      CohortPatient: { PatientId: patientId, CohortId: cohortId },
    };
  } else {
    reqObject = {
      Cohort: { Name: cohortName },
      CohortPatient: { PatientId: patientId },
    };
  }
  const res = await apiClient.post<ISaveCohortResponse>(`/cohort/saveCohort`, reqObject);
  return res;
};

const getCohortPatientList = async (queryString: string) => {
  const res = await apiClient.get<ICohortPatientListResponse>(
    `/cohort/getCohortPatientList/${queryString}`
  );
  return res;
};

const updateCohort = async (cohortId: number, reqObj: IUpdateCohortRequest) => {
  const res = await apiClient.put<IUpdateCohortResponse>(
    `/cohort/updateCohort/${cohortId}`,
    reqObj
  );
  return res;
};

const deleteCohortPatient = async (cohortId: number, patientId: number) => {
  const res = await apiClient.delete<IDeleteCohortPatientResponse>(
    `/cohort/removePatientFromCohort/${cohortId}/${patientId}`
  );
  return res;
};

export const cohortService = {
  getCohortList,
  saveCohort,
  getCohortPatientList,
  updateCohort,
  deleteCohortPatient,
};
