import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { projectService } from "../api";
import { DropdownOption } from "../interfaces";
import { RootState } from "../store";

export const useFetchPatientStatusOptions = () => {
  const { clientId } = useSelector((state: RootState) => state.user.user);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([] as DropdownOption[]);

  const fetchPatientStatusList = useCallback(async () => {
    setStatusLoading(true);
    const { data } = await projectService.getPatientStatusList(clientId);
    setStatusOptions(data.data.map((item) => ({ value: item.Name, id: item.Id })));
    setStatusLoading(false);
  }, [clientId]);

  return {
    fetchPatientStatusList,
    patientStatusLoading: statusLoading,
    setPatientStatusLoading: setStatusLoading,
    patientStatusOptions: statusOptions,
  };
};
