import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import { clientService } from "../../api";
import {
  RootState,
  setClients,
  deleteClient,
  updateDeleteConfirmation,
  openModal,
  setClientEditDelete,
} from "../../store";
import { MODAL_TYPE, PAGE_URL } from "../../utils";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { EditIcon, DeleteIcon } from "../../assets/icons";

interface DataType {
  key: React.Key;
  name: string;
  description: string;
  action: string;
}

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<DataType> => [
  {
    title: "Client Name",
    key: "name",
    dataIndex: "name",
    render: (text, value) => <Link to={`${PAGE_URL.CLIENT_DETAIL}/${value.key}`}>{text}</Link>,
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (text, value) => (
      <div className="editDelete gp jcc flex">
        <EditIcon
          className="cursor-pointer"
          onClick={() => {
            dispatch(setClientEditDelete({ id: Number(value.key), isEdit: true }));
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_CLIENT));
          }}
        />
        <DeleteIcon
          className="cursor-pointer"
          onClick={() => {
            dispatch(setClientEditDelete({ id: Number(value.key), isEdit: false }));
            dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
          }}
        />
      </div>
    ),
  },
];

export const SuperAdminDashboardContainer = () => {
  const dispatch = useDispatch();
  const { list, id: clientId } = useSelector((state: RootState) => state.data.clients);
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const [loading, setLoading] = useState(false);
  const clientsData: DataType[] = list.map((item) => {
    const { description, id, name } = item;
    return { key: id, name, description, action: "" };
  });

  useEffect(() => {
    const fetch = async () => {
      const { data } = await clientService.getAll();
      const clientList = data.data;
      const res = clientList.map((item) => ({
        id: item.Id,
        name: item.Name,
        description: item.Description,
      }));
      dispatch(setClients(res));
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    const deleteClientAPI = async () => {
      setLoading(true);
      await clientService.deleteOne(clientId);
      dispatch(deleteClient(clientId));
      dispatch(updateDeleteConfirmation(false));
      setLoading(false);
    };
    if (isDeleteConfirmed && clientId !== -1) deleteClientAPI();
  }, [clientId, dispatch, isDeleteConfirmed]);

  return (
    <>
      <div className="flex jcsb aic clientSearch">
        <p className="table-heading">Clients</p>

        <Button
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_CLIENT));
          }}
        >
          Create New Client
        </Button>
        <div className="clientList">
          <Table
            className="client-table user-table"
            dataSource={clientsData}
            columns={getColumns(dispatch)}
            loading={loading}
            pagination={false}
          ></Table>
        </div>
      </div>
    </>
  );
};
