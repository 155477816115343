import { SorterResult } from "antd/lib/table/interface";
import { useCallback, useEffect, useState } from "react";
import { DropdownOption } from "../interfaces";
import { FetchPatientsObj } from "./useFetchPatients";
import { useFetchPatientStatusOptions } from "./useFetchPatientStatusOptions";
import { usePagination } from "./usePagination";

interface Props {
  onPatientsList: boolean;
}

interface ColumnSort extends SorterResult<any> {
  sortOrder: "asc" | "desc" | null;
}

const initialPatientStatusSelectedOption = {
  id: -1,
  value: "View all",
};

export const usePatientFilter = ({ onPatientsList }: Props) => {
  const { fetchPatientStatusList, patientStatusLoading, patientStatusOptions } =
    useFetchPatientStatusOptions();
  const { page, onPaginationChange, pageSize, pageSizeOptions } = usePagination();

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({} as ColumnSort);
  const [patientStatusSelectedOption, setPatientStatusSelectedOption] = useState(
    initialPatientStatusSelectedOption
  );

  const onSortChange = (newSort: any) => {
    if (newSort.order === "ascend") setSort({ ...newSort, sortOrder: "asc" });
    else if (newSort.order === "descend") setSort({ ...newSort, sortOrder: "desc" });
    else setSort({ ...newSort, sortOrder: null });
  };

  const onSearchChange = (str: string) => {
    onPaginationChange(1, pageSize);
    setSearch(str);
  };

  const onPatientStatusChange = (value: string, option: DropdownOption) => {
    onPaginationChange(1, pageSize);
    setPatientStatusSelectedOption(option);
  };

  useEffect(() => {
    const getPatientStatusOptions = async () => {
      if (onPatientsList) {
        await fetchPatientStatusList();
      }
    };
    getPatientStatusOptions();
  }, [fetchPatientStatusList, onPatientsList]);

  const getPatientFilterObject = useCallback(
    (overrideObj?: FetchPatientsObj) => {
      let obj: FetchPatientsObj = {
        limit: pageSize,
        offset: page - 1,
        search: search,
      };
      if (patientStatusSelectedOption.id !== -1)
        obj = { ...obj, statusId: patientStatusSelectedOption.id };
      if (sort.sortOrder && sort.field) {
        obj = { ...obj, sortField: sort.field.toString(), sortType: sort.sortOrder };
      }
      return { ...obj, ...overrideObj };
    },
    [page, pageSize, patientStatusSelectedOption.id, search, sort.field, sort.sortOrder]
  );

  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    searchFilter: { searchKeyword: search, onSearchChange },
    patientStatusFilter: {
      patientStatusLoading,
      patientStatusOptions: [initialPatientStatusSelectedOption, ...patientStatusOptions],
      patientStatusSelectedOption,
      onPatientStatusChange,
    },
    getPatientFilterObject,
  };
};
