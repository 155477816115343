import React, { useState } from "react";
import { ListDataIcon, TwoByTwoTileOption } from "../../../assets/icons";
import { Button } from "antd";
import { DATA_ADMIN_PERMISSIONS, MODAL_TYPE } from "../../../utils";
import { useDispatch } from "react-redux";
import { openModal } from "../../../store";
import { useUser } from "../../../hooks";
import { ProjectsList } from "../../tables";
import { ProjectListInTiles } from "./ProjectListInTiles";

enum VIEW_STYLE {
  TILES,
  LIST,
}

interface Props {
  className?: string;
}

export const ProjectListInTableAndTile = ({ className }: Props) => {
  const dispatch = useDispatch();
  const { roleCode } = useUser();
  const [viewStyle, setViewStyle] = useState(VIEW_STYLE.TILES);

  return (
    <div className="pt-20">
      <div className="flex aic jcsb">
        <h3 className="no-padding">Projects</h3>
        <div className="flex gp aic">
          <TwoByTwoTileOption
            className={`view-control cursor-pointer ${
              viewStyle === VIEW_STYLE.TILES ? "active" : ""
            } `}
            onClick={() => setViewStyle(VIEW_STYLE.TILES)}
          />
          <ListDataIcon
            className={`view-control cursor-pointer ${
              viewStyle === VIEW_STYLE.LIST ? "active" : ""
            } `}
            onClick={() => setViewStyle(VIEW_STYLE.LIST)}
          />
          {DATA_ADMIN_PERMISSIONS.includes(roleCode) && (
            <Button
              className="outline"
              onClick={() => {
                dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
              }}
            >
              Add New Project
            </Button>
          )}
        </div>
      </div>
      <div>
        {viewStyle === VIEW_STYLE.TILES ? (
          <ProjectListInTiles className={className ?? ""} />
        ) : (
          <ProjectsList atDashboard={true} />
        )}
      </div>
    </div>
  );
};
