import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useOutletContext } from "react-router-dom";
import { useFetchSearchResult } from "../../../hooks";
import { RootState, setSearchResult } from "../../../store";
import { SearchResults } from ".";
import "./search-result.scss";

export const SearchResult = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    fetchSearchResults,
    searchLoading,
    searchResult: data,
    isApiCalled,
  } = useFetchSearchResult();
  const autoSearch = searchParams.get("auto") === "true";
  const [callApi, setCallApi] = useOutletContext<any>();
  const { result } = useSelector((state: RootState) => state.search);

  useEffect(() => {
    if (isApiCalled) {
      dispatch(setSearchResult(data));
    }
  }, [isApiCalled, data, dispatch]);

  useEffect(() => {
    if (callApi || autoSearch) {
      fetchSearchResults();
      setCallApi(false);
    }
  }, [autoSearch, callApi, fetchSearchResults, setCallApi]);

  return (
    <div className="search-result">
      <SearchResults result={result} loading={searchLoading} />
    </div>
  );
};
