import React from "react";
import { IconsProps } from "./Icons";

export const BodyMassIndexIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 49 48"}
      width={width ? width : "49"}
      height={height ? height : "48"}
      fill={fill ? fill : "currentColor"}
    >
        <rect xmlns="http://www.w3.org/2000/svg" x="0.381836" width="48" height="48" rx="6" fill="#E4FF98"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M24.3818 15C25.4427 15 26.4601 15.4214 27.2103 16.1716C27.9604 16.9217 28.3818 17.9391 28.3818 19C28.3818 19.73 28.1918 20.41 27.8418 21H30.3818C31.3318 21 32.1318 21.67 32.3318 22.56C34.3418 30.57 34.3818 30.78 34.3818 31C34.3818 31.5304 34.1711 32.0391 33.796 32.4142C33.421 32.7893 32.9123 33 32.3818 33H16.3818C15.8514 33 15.3427 32.7893 14.9676 32.4142C14.5925 32.0391 14.3818 31.5304 14.3818 31C14.3818 30.78 14.4218 30.57 16.4318 22.56C16.6318 21.67 17.4318 21 18.3818 21H20.9218C20.5644 20.3944 20.3777 19.7032 20.3818 19C20.3818 17.9391 20.8033 16.9217 21.5534 16.1716C22.3036 15.4214 23.321 15 24.3818 15ZM24.3818 17C23.8514 17 23.3427 17.2107 22.9676 17.5858C22.5926 17.9609 22.3818 18.4696 22.3818 19C22.3818 19.5304 22.5926 20.0391 22.9676 20.4142C23.3427 20.7893 23.8514 21 24.3818 21C24.9123 21 25.421 20.7893 25.796 20.4142C26.1711 20.0391 26.3818 19.5304 26.3818 19C26.3818 18.4696 26.1711 17.9609 25.796 17.5858C25.421 17.2107 24.9123 17 24.3818 17ZM18.3818 23V31H23.3818V29H20.3818V23H18.3818ZM25.3818 23V31H28.3818C29.4918 31 30.3818 30.11 30.3818 29V28.5C30.3818 27.93 30.1318 27.38 29.7018 27C30.1318 26.62 30.3818 26.07 30.3818 25.5V25C30.3818 23.89 29.4918 23 28.3818 23H25.3818ZM27.3818 25H28.3818V26H27.3818V25ZM27.3818 28H28.3818V29H27.3818V28Z" fill="#8DA054"/>
        
      </svg>
  );
};
