import React, { useState } from "react";
import { Modal, AutoComplete } from "antd";
import { Button, Form } from "antd";
import { AssignProjectProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { projectService } from "../../../api";
import { setUserWithProjects } from "../../../store";
import { useDispatch } from "react-redux";

type OptionType = { value: string; id: number };

const AssignProject = ({
  visibility,
  projects,
  clientId,
  userId,
  editObj,
  handleClose,
}: AssignProjectProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(-1);
  const [error, setError] = useState("");
  const options: OptionType[] = projects
    .filter((item) => item.status)
    .map((item) => ({
      value: item.Name,
      id: item.Id,
    }));

  const onSelectChange = (value: string, option: OptionType) => {
    setSelectedOption(option.id);
    setError("");
  };

  const onChange = (value: string) => {
    const find = options.findIndex((item) => item.value === value);
    if (find === -1) setSelectedOption(-1);
  };

  const handleSubmit = async () => {
    if (userId !== -1 && selectedOption !== -1) {
      setLoading(true);
      const isAlreadyInProject = editObj.projects.findIndex(
        (item) => item.project_id === selectedOption
      );
      if (isAlreadyInProject !== -1) {
        setError("Project is already assigned for user");
        setLoading(false);
      } else {
        await projectService.addUser(clientId, selectedOption, editObj.id);
        const { data } = await projectService.getUsersWithProjects(clientId);
        dispatch(setUserWithProjects(data.data));
        setLoading(false);
        handleClose();
      }
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={580}
      title="Add Client"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Assign to Project</h1>
      </div>
      <div className="auto-complete addClient-content">
        <p>Project Name</p>
        <AutoComplete
          // style={{ width: 200 }}
          options={options}
          onSelect={onSelectChange}
          onChange={onChange}
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        {error && <h3 className="error">{error}</h3>}
        <Form.Item className="tr">
          <Button
            className="outline"
            // type="primary"
            onClick={handleSubmit}
            disabled={selectedOption === -1}
            loading={loading}
          >
            Assign to Project
          </Button>
        </Form.Item>
      </div>
    </Modal>
  );
};

export default AssignProject;
