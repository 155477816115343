import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { IHEDISMeasureInsight, IPatient, ProjectSpecificTiles } from "../../interfaces";
import {
  AskAQuestion,
  ClinicalInsights,
  HedisMeasureInsights,
  PatientInsightActionItems,
  PatientInsightTiles,
  PatientInsightsTabs,
  ProjectSpecificInsightTiles,
  Summary,
} from "./patient-insight-container";
import { PATIENT_INSIGHT_TABS_NEW, PatientDetailCardProps } from ".";
import { PatientProjects } from "../../api";
import "./patient-insight-container.scss";
import { PatientInsightHeader } from "./patient-insight-container/pateint-insight-header/PatientInsightHeader";
import { PatientPagination } from "./patient-insight-container/pateint-insight-header";
import { appendTabToRouteQuery } from "../../utils";
import { useSearchParams } from "react-router-dom";

// const data: IQuestAns[]= [
//   {
//     question: "What is the capital of France?",
//     answer: "The capital of France is Paris. It is known for its rich history, art, culture, and world-famous landmarks such as the Eiffel Tower, Louvre Museum, and Notre-Dame Cathedral."
//   },
//   {
//     question: "What is the largest mammal on Earth?",
//     answer: "The blue whale is the largest mammal on Earth. It can grow up to 100 feet long and weigh as much as 200 tons. Blue whales are known for their unique feeding method, filter-feeding on tiny crustaceans called krill."
//   },
//   {
//     question: "What is the chemical formula for water?",
//     answer: "The chemical formula for water is H2O. It consists of two hydrogen atoms bonded to a single oxygen atom, forming a bent molecular shape. Water is essential for life and is known for its unique properties, such as high heat capacity, universal solvent ability, and surface tension."
//   },
//   {
//     question: "Who is the author of the play Romeo and Juliet?",
//     answer: "The play Romeo and Juliet was written by William Shakespeare, one of the most famous playwrights in history. It is a tragedy about two young lovers from feuding families in Verona, Italy, and is known for its poetic language, memorable characters, and timeless themes of love and fate."
//   },
//   {
//     question: "What is the tallest mountain in the world?",
//     answer: "Mount Everest is the tallest mountain in the world, reaching a peak height of 8,848 meters (29,029 feet). It is located in the Himalayas, on the border of Nepal and Tibet, and is known for its challenging climbing conditions and breathtaking panoramic views from the summit."
//   }
// ];

interface Props {
  patientInfo: IPatient;
  selectedProject: PatientProjects;
  patientProjects: PatientProjects[];
  hedisMeasures: IHEDISMeasureInsight;
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
  setSelectedProject: (item: PatientProjects) => void;
  reviewStatusMap: PatientDetailCardProps[];
  patientProjectsLoading: boolean;
  isHedisMeasureLoading: boolean;
}

export const PatientInsightContainer = (props: Props) => {
  const {
    patientInfo,
    patientProjects,
    selectedProject,
    setSelectedProject,
    reviewStatusMap,
    patientProjectsLoading,
    setReviewStatusMap,
    hedisMeasures,
    isHedisMeasureLoading,
  } = props;

  const projectSpecific = useMemo(() => {
    return patientInfo.project_specific
      ? patientInfo.project_specific
      : ({} as ProjectSpecificTiles);
  }, [patientInfo.project_specific]);

  const [selectedTab, setSelectedTab] = useState(PATIENT_INSIGHT_TABS_NEW.SUMMARY);
  const [component, setComponent] = useState(<></>);
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    setSelectedTab(tab || PATIENT_INSIGHT_TABS_NEW.SUMMARY);
  }, []);

  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case PATIENT_INSIGHT_TABS_NEW.SUMMARY:
        res = <Summary patientInfo={patientInfo} />;
        break;
      case PATIENT_INSIGHT_TABS_NEW.CLINICAL_INSIGHTS:
        res = <ClinicalInsights {...{ patientProjects, patientInfo, selectedProject }} />;
        break;
      case PATIENT_INSIGHT_TABS_NEW.HEDIS_MEASURE_INISGHTS:
        res = <HedisMeasureInsights {...{ patientInfo, hedisMeasures, isHedisMeasureLoading }} />;
        break;
      case PATIENT_INSIGHT_TABS_NEW.ASK_A_QUESTION:
        res = <AskAQuestion {...{ patientInfo }} />;
        break;
    }
    setComponent(res);
    appendTabToRouteQuery(selectedTab);
  }, [selectedTab, patientInfo, patientProjects, hedisMeasures, isHedisMeasureLoading]);

  return (
    <>
      <div className="flex jcsb aic m-b">
        <PatientInsightActionItems
          {...{ selectedProject, patientProjects, patientInfo, setReviewStatusMap }}
        />
        <PatientPagination patientId={patientInfo.patientId} />
      </div>
      <PatientInsightsTabs {...{ onChange, selectedTab }} />
      {component}
      {/* <PatientInsightHeader
        {...{
          patientInfo,
          patientProjects,
          selectedProject,
          setSelectedProject,
          reviewStatusMap,
          patientProjectsLoading,
          setReviewStatusMap,
        }}
      /> */}
      {/* <PatientInsightTiles patientInfo={patientInfo} />
      {patientProjects.length > 0 && (
        <ProjectSpecificInsightTiles
          projectSpecific={projectSpecific}
          patientId={patientInfo.patientId}
          threshold_confidence_score={patientInfo.threshold_confidence_score}
          biomarkers={patientInfo.biomarkers}
          riskFactors={patientInfo.risk_factor}
        />
      )} */}
    </>
  );
};
