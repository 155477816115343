import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IQuestAns {
  question: string;
  answer: string;
}
interface IQuesAnsInitialState {
  quesAns: IQuestAns[];
  patientId: string;
}

export const quesAnsInitialState: IQuesAnsInitialState = {
  quesAns: [] as IQuestAns[],
  patientId: "",
};

export const quesAnsSlice = createSlice({
  name: "quesAns",
  initialState: quesAnsInitialState,
  reducers: {
    setQuestionsAnswers: (state: IQuesAnsInitialState, { payload }: PayloadAction<IQuestAns[]>) => {
      state.quesAns = [...payload];
    },
    resetQuestionAnswerState: (state: IQuesAnsInitialState, { payload }: PayloadAction<string>) => {
      state.quesAns = quesAnsInitialState.quesAns;
      state.patientId = payload;
    },
  },
});

export const { setQuestionsAnswers, resetQuestionAnswerState } = quesAnsSlice.actions;
export default quesAnsSlice.reducer;
