import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table } from "antd";
import { SocialHistory } from "../../../../interfaces";
import {
  CONFIDENCE_SCORE_INFO,
  EVIDENCE_VIEWER_URL,
  NoResultsFoundMessage,
  PatientCardWrapper,
  PatientInsightTag,
  ViewMoreResults,
} from "../tile-helper-components";
import { scoreToPercentage } from "../../../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PATIENT_INSIGHT_TILES } from "..";

interface DataType {
  text: string;
  score: string;
  key: React.Key;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Source Text",
    dataIndex: "text",
    key: "text",
  },
  {
    title: "Confidence Score",
    filters: [],
    filterIcon: () => <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />,
    dataIndex: "score",
    key: "score",
  },
];

interface Props {
  socialHistory: SocialHistory[];
  patientId: number;
  threshold: number;
}

export const SocialHistoryCard = ({ socialHistory, patientId, threshold }: Props) => {
  const sorted = socialHistory.sort((a, b) => b.score - a.score);
  const aboveEighty = sorted.filter((a) => a.score >= threshold / 100);
  const belowEighty = sorted.filter((a) => a.score < threshold / 100);

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };
  return (
    <>
      <PatientCardWrapper title={PATIENT_INSIGHT_TILES.socialHistory}>
        <div className="view-more-text">
          {/* <Table
            columns={columns}
            locale={{
              emptyText: (
                <NoResultsFoundMessage
                  aboveEightyLength={aboveEighty.length}
                  belowEightyLength={belowEighty.length}
                  threshold={threshold}
                />
              ),
            }}
            dataSource={
              aboveEighty
                ? aboveEighty.map((item, index) => ({
                    text: item.text,
                    score: scoreToPercentage(item.score),
                    key: `patient-socialHistory-${index}`,
                  }))
                : []
            }
            pagination={false}
            className="two-row"
            rowClassName={"cursor-pointer"}
            onRow={(record) => ({ onClick: () => handleSearchNavigate(record.text) })}
          ></Table> */}
          <div>
            {aboveEighty.length > 0 && (
              <div className="flex gp-10 aic m-b">
                <h5 className="data-title">Social History and Confidence Score</h5>
                <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />
              </div>
            )}
            <div className="tiles-max-height flex gp-10">
              {aboveEighty?.map((item, index) => (
                <div
                  className="patient-insight-tag"
                  key={`patient-socialHistory-${index}`}
                  onClick={() => handleSearchNavigate(item.text)}
                >
                  <PatientInsightTag text={item.text} value={scoreToPercentage(item.score)} />
                </div>
              ))}
              <NoResultsFoundMessage
                aboveEightyLength={aboveEighty.length}
                belowEightyLength={belowEighty.length}
                threshold={threshold}
              />
            </div>
          </div>
          <ViewMoreResults
            searchParanms={{
              search: [...new Set(belowEighty.map((a) => a.text))].join(","),
              patient_id: patientId,
            }}
            belowEightyLength={belowEighty.length}
            threshold={threshold}
          />
        </div>
      </PatientCardWrapper>
    </>
  );
};
