import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeSavedSearch, RootState, setSavedSearch, updatedRecentSearch } from "../../../store";
import { DeleteIcon } from "../../../assets/icons";
import { RecentAndSaveSearch, searchService } from "../../../api";
import { getLocalDateTimeStringFromISO, PAGE_URL } from "../../../utils";
import { Loading } from "../../../components";

export const SavedSearches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { saved } = useSelector((state: RootState) => state.search);
  const [loading, setLoading] = useState(false);
  const [updatingRecordId, setUpdatingRecordId] = useState(-1);

  const handleDelete = async (record: RecentAndSaveSearch) => {
    setUpdatingRecordId(record.id);
    const { data } = await searchService.bookmarkSearch(record.id, !record.featured);
    dispatch(updatedRecentSearch(data.data));
    dispatch(removeSavedSearch(data.data.id));
    setUpdatingRecordId(-1);
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const { data } = await searchService.getSaved();
      dispatch(setSavedSearch(data.data));
      setLoading(false);
    };
    fetch();
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={`saved-searches`}>
          <ul className="scrollBar">
            {saved.map((item, i) => (
              <li key={`saved-searches-${i}`} className="flex aic jcsb searches-li">
                <div
                  className="searches-title cursor-pointer text_ellipsis"
                  onClick={() =>
                    navigate({
                      pathname: `${PAGE_URL.SEARCH.LAYOUT}/${PAGE_URL.SEARCH.RESULT}`,
                      search: createSearchParams({
                        auto: "true",
                        search: item.query.join(","),
                        type: "All",
                      }).toString(),
                    })
                  }
                  title={[...item.query].slice(0, 3).join(", ")}
                >
                  {[...item.query].slice(0, 3).join(", ")}
                  <div className="searches-date">
                    {getLocalDateTimeStringFromISO(item.updatedAt)}
                  </div>
                </div>

                <div
                  className="flex aic searches-li-right cursor-pointer"
                  onClick={() => handleDelete(item)}
                >
                  {updatingRecordId === item.id ? (
                    <Loading tip="" size="small" />
                  ) : (
                    <DeleteIcon className="saved-icon" />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
