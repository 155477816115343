import React from "react";
import { RecentSearches, SavedSearches } from "../../search/dashboard-saved-and-recent-search";
import "./top-searches.scss";

export const TopSearches = () => {
  return (
    <div className="ant-card topSearch">
      <RecentSearches />
      <SavedSearches />
    </div>
  );
};
