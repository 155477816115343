import React, { useEffect, useState } from "react";
import {
  ProjectDetailOverview,
  // ProjectDetailInsights,
  ProjectDetailDocuments,
  // ProjectDetailPatientRecommender,
  ProjectSpecificConfigurations,
  ProjectDetailTabs,
  // ProjectTeam,
  PROJECT_DETAIL_TABS,
} from "./project-detail-container";
import { AssociatedPatientsList } from "../patients-list/patients-list-container";
import { ProjectDetail } from "../../interfaces";
// import { ProjectUsers } from "../tables";
import "./project-detail-container.scss";
import { useSearchParams } from "react-router-dom";
import { appendTabToRouteQuery } from "../../utils";

interface Props {
  projectDetail: ProjectDetail;
  clientId: number;
  roleCode: string;
}

export const ProjectDetailContainer = ({ projectDetail, roleCode, clientId }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("tab") || PROJECT_DETAIL_TABS.OVERVIEW.name
  );
  const [component, setComponent] = useState(<></>);

  const onTabChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case PROJECT_DETAIL_TABS.OVERVIEW.name:
        res = <ProjectDetailOverview description={projectDetail.Description} />;
        break;
      // case PROJECT_DETAIL_TABS.PROJECT_INSIGHTS.name:
      //   res = <ProjectDetailInsights projectId={projectDetail.Id} />;
      //   break;
      // case PROJECT_DETAIL_TABS.PROJECT_TEAM.name:
      //   res = <ProjectUsers projectDetail={projectDetail} />;
      //   break;
      case PROJECT_DETAIL_TABS.ASSIGNED_PATIENTS.name:
        res = <AssociatedPatientsList projectId={projectDetail.Id} />;
        break;
      case PROJECT_DETAIL_TABS.PATIENT_DOCUMENTS.name:
        res = <ProjectDetailDocuments projectId={projectDetail.Id} />;
        break;
      case PROJECT_DETAIL_TABS.PROJECT_CONFIGURATION.name:
        res = <ProjectSpecificConfigurations projectDetail={projectDetail} />;
        break;
      // case PROJECT_DETAIL_TABS.RECOMMENDER.name:
      //   res = <ProjectDetailPatientRecommender projectId={projectDetail.Id} />;
      //   break;
    }
    setComponent(res);
    appendTabToRouteQuery(selectedTab);
  }, [clientId, projectDetail, selectedTab]);

  return (
    <div className="project-detail-container">
      <div className="project-detail-container-left">
        <ProjectDetailTabs {...{ selectedTab, onTabChange, roleCode }} />
        <div className="tabs-content">{component}</div>
      </div>
      {/* <ProjectTeam {...{ projectDetail }} /> */}
    </div>
  );
};
