import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import {
  PatientInsightConfidenceConfigurationSection,
  PatientInsightConfigurationSection,
} from ".";
import { ConfigurationObj } from "../../../api";
import { EditIcon } from "../../../assets/icons";
import { Loading } from "../../../components";
import { useGlobalConfiguration } from "../../../hooks/useGlobalConfiguration";
import "../../configuration/service-config.scss";
import "./patient-insight-configuration.scss";

const CONFIG = [
  { key: "Threshold Confidence Score", title: "Subject Display Confidence Score" },
  { key: "Inclusion Exclusion", title: "List the Inclusion & Exclusion criterias" },
  { key: "Infectious Disease", title: "List the Infectious Diseases to be reviewed" },
  { key: "Conditions", title: "List the Conditions to be searched" },
].map((item) => ({ ...item, key: ["GC", ...item.key.toUpperCase().split(" ")].join("_") }));

export const PatientInsightConfigurations = () => {
  const {
    fetchConfigurations,
    updateConfigurations,
    configurations,
    loading,
    updateConfigLoading,
  } = useGlobalConfiguration();
  const [isEdit, setIsEdit] = useState(false);
  const [localUpdatedConfigurations, setLocalUpdatedConfigurations] = useState(
    [] as ConfigurationObj[]
  );

  const findConfiguration = (keyIndex: number): ConfigurationObj => {
    const key = CONFIG[keyIndex].key;
    const title = CONFIG[keyIndex].title;
    const index = localUpdatedConfigurations.findIndex((item) => item.field_key === key);
    if (index !== -1) return { ...localUpdatedConfigurations[index], field_name: title };
    return { field_content: [], field_key: key, field_name: title };
  };

  const editConfiguration = (obj: ConfigurationObj) => {
    const key = obj.field_key;
    const filteredList = localUpdatedConfigurations.filter((item) => item.field_key !== key);
    setLocalUpdatedConfigurations([...filteredList, obj]);
  };

  const handleEditClick = async () => {
    if (isEdit) {
      // call API - user is confirming edit
      await updateConfigurations(localUpdatedConfigurations);
    }
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    fetchConfigurations();
  }, [fetchConfigurations]);

  useEffect(() => {
    setLocalUpdatedConfigurations(configurations);
  }, [configurations]);

  return (
    <div className="project-detail-config">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Button
            className="outline flex-center"
            loading={updateConfigLoading}
            icon={isEdit ? <CheckOutlined /> : <EditIcon />}
            onClick={() => handleEditClick()}
          >
            {isEdit ? "Update Configurations" : "Edit Configurations"}
          </Button>
          <div className="basic-detail-content p-20">
            <PatientInsightConfidenceConfigurationSection
              isEdit={isEdit}
              configuration={findConfiguration(0)}
              editConfiguration={editConfiguration}
            />
          </div>
          {CONFIG.slice(1).map((item, index) => (
            <div className="basic-detail-content p-20" key={`patient-insight-config-${index}`}>
              <PatientInsightConfigurationSection
                isEdit={isEdit}
                configuration={findConfiguration(index + 1)}
                editConfiguration={editConfiguration}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
