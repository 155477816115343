import React, { useMemo } from "react";
import { PatientProjects } from "../../../api";
import { PatientInsightsQuickLinks } from "./pateint-insight-header";
import { PatientInsightTiles } from "./PatientInsightTiles";
import { ProjectSpecificInsightTiles } from "./ProjectSpecificInsightTiles";
import { IPatient, ProjectSpecificTiles } from "../../../interfaces";

interface Props {
  patientProjects: PatientProjects[];
  patientInfo: IPatient;
  selectedProject: PatientProjects
}

export const ClinicalInsights = ({ patientProjects, patientInfo, selectedProject }: Props) => {
  const projectSpecific = useMemo(() => {
    return patientInfo.project_specific
      ? patientInfo.project_specific
      : ({} as ProjectSpecificTiles);
  }, [patientInfo.project_specific]);

  return (
    <div>
      <PatientInsightsQuickLinks displayProjectSpecificTiles={patientProjects.length > 0} />
      <div className="clinical-insight">
        <h4 className="title-bd-bt">Clinical factors derived from Unstructured data</h4>
        {/*{patientProjects.length > 0 && (
          <ProjectSpecificInsightTiles
            projectSpecific={projectSpecific}
            patientId={patientInfo.patientId}
            threshold_confidence_score={patientInfo.threshold_confidence_score}
            biomarkers={patientInfo.biomarkers}
            riskFactors={patientInfo.risk_factor}
          />
        )} */}
        <PatientInsightTiles patientInfo={patientInfo} selectedProject={selectedProject} />
      </div>
    </div>
  );
};
