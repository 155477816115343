import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { MailServiceConfiguration } from "../configuration";
import { UsersList } from "../tables";

interface Props {
  clientId: number;
}

export const ClientDetailContainer = ({ clientId }: Props) => {
  const dispatch = useDispatch();

  return (
    <>
      <MailServiceConfiguration clientId={clientId} />
      <div className="clientList m-t">
        <div className="flex jcsb aic clientDetails-title">
          <p className="table-heading">Enterprise Admins</p>
          <Button
            className="outline"
            onClick={() => {
              dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER));
            }}
          >
            Add Enterprise Admin
          </Button>
        </div>
        <UsersList loading={false} />
      </div>
    </>
  );
};
