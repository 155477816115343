import React from "react";
import {
  HEDISMeasureInsightsQuickLinks,
  HEDISMeasureInsightsTiles,
} from "./hedis-measure-insights";
import { IHEDISMeasureInsight, IPatient } from "../../../interfaces";
import { Loading } from "../../../components";

interface Props {
  hedisMeasures: IHEDISMeasureInsight;
  patientInfo: IPatient;
  isHedisMeasureLoading: boolean;
}

export const HedisMeasureInsights = ({
  hedisMeasures,
  patientInfo,
  isHedisMeasureLoading,
}: Props) => {
  return isHedisMeasureLoading ? (
    <Loading />
  ) : (
    <>
      <HEDISMeasureInsightsQuickLinks />
      <HEDISMeasureInsightsTiles {...{ hedisMeasures, patientInfo }} />
    </>
  );
};
