import { Dispatch } from "redux";
import { connect } from "react-redux";
import AssignProject from "./AssignProject";
import { closeModal, RootState, setUserWithProjectsEdit } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { Project } from "../../../interfaces";
import { UserWithProject } from "../../../store/data/types";

type StateProps = {
  visibility: boolean;
  projects: Project[];
  clientId: number;
  userId: number;
  editObj: UserWithProject;
};
type DispatchProps = {
  handleClose: () => void;
};
export type AssignProjectProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const id = state.data.users.usersWithProject.id;
  let editObj = {} as UserWithProject;
  if (id !== -1) {
    const obj = state.data.users.usersWithProject.list.find((item) => item.id === id);
    if (obj) editObj = obj;
  }
  return {
    visibility: state.ui.modal[MODAL_TYPE.ASSIGN_PROJECT],
    projects: state.data.projects.list,
    clientId: state.user.user.clientId,
    userId: state.data.users.usersWithProject.id,
    editObj,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(setUserWithProjectsEdit(-1));
    dispatch(closeModal(MODAL_TYPE.ASSIGN_PROJECT));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignProject);
