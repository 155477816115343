import axios, { AxiosRequestConfig } from "axios";
import { store } from "../App";
import { logoutUser, setPreviousEmailAndUrl } from "../store";
import { PAGE_URL } from "../utils";
import { useNavigate } from "react-router-dom";

export const authHeaders = (headers: any, token: string): any => {
  const namespace = "x-access-token";
  headers[namespace] = token;
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = store.getState().user.user.accessToken;
  return authHeaders(headers, token || "");
};

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
const instance = axios.create(defaultOptions);

const hedisDefaultOptions = {
  baseURL: process.env.REACT_APP_API_HEDIS_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

export const hedisInstance = axios.create(hedisDefaultOptions);

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    //   set user token here
    config.headers = withAuthHeaders({});
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => res,
  (error: any) => {
    // handle user session refresh here
    if (error.response.status === 401 || error.response.status === 403) {
      if (window.location.pathname !== "/") {
        store.dispatch(
          setPreviousEmailAndUrl(`${window.location.pathname}${window.location.search}`)
        );
      }
      store.dispatch(logoutUser());
      useNavigate()(PAGE_URL.LOGIN);
    }
    if (error.response.status >= 500) {
      error.response.message = "Something went wrong!";
    }
    return Promise.reject(error);
  }
);

export default instance;
