import { CloseOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
import React, { useState } from "react";
import { PositiveFeedbackProps } from ".";
import TextArea from "antd/lib/input/TextArea";
import { openNotificationWithIcon } from "../../../utils";
import { ThumbsDown, ThumbsUp } from "../../../assets/icons";
import { feedbackService } from "../../../api/feedback";

export const FeedbackForm = ({
  question,
  answer,
  patientId,
  feedbackType,
  visibility,
  handleClose,
}: PositiveFeedbackProps) => {
  const [feedback, setFeedback] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { data } = await feedbackService.addFeedback({
        patientId,
        question,
        answer,
        feedback,
        feedbackType: feedbackType === 1 ? true : false,
      });
      if (data.message === "Successfully Saved") {
        openNotificationWithIcon("", data.message, "success");
        handleClose();
      }
    } catch (e: any) {
      openNotificationWithIcon("", e.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <div className="flex gp-10 aic">
          {feedbackType === 1 ? <ThumbsUp /> : <ThumbsDown />}

          <h4 className="mb-0">Provide additional feedback</h4>
        </div>
      </div>
      <div className="addClient-content no-pd-tp">
        <TextArea
          placeholder="Provide Feedback"
          onChange={(e) => {
            setFeedback(e.target.value);
          }}
          value={feedback}
          rows={5}
        />
        <div className="tr m-t">
          <Button loading={isLoading} className="outline" onClick={handleSubmit}>
            Submit Feedback
          </Button>
        </div>
      </div>
    </Modal>
  );
};
