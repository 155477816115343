import { apiClient } from ".";
import { IPagination } from "../interfaces";

interface IGetAnswerRequest {
  patientId: number;
  question: string;
}

interface IGetAnswerResponse {
  message: string;
  data: {
    answer: string;
    score: number;
  };
}

interface IGetQAHistoryRequest {
  patientId: number;
  limit: number;
  offset: number;
}

export interface IQA {
  Id: number;
  PatientId: number;
  Question: string;
  Answer: string;
  CreatedBy: number;
  createdAt: string;
  updatedAt: string;
}

interface IGetQAHistoryResponse {
  message: string;
  data: IQA[];
  pagination: IPagination;
}

interface IGetSingleQARequest {
  patientId: number;
  logId: number;
}

interface IGetSingleQAResponse {
  message: string;
  data: IQA;
}

interface IClearHistoryResponse {
  message: string;
}

const getAnswer = async (obj: IGetAnswerRequest) => {
  const res = await apiClient.post<IGetAnswerResponse>(
    `/patient/qamodel/query/${obj.patientId}`,
    obj
  );
  return res;
};

const getQAHistory = async ({ limit, offset, patientId }: IGetQAHistoryRequest) => {
  const res = await apiClient.get<IGetQAHistoryResponse>(
    `patient/qamodel/logs/${patientId}?limit=${limit}&offset=${offset}`
  );
  return res;
};

const getSingleQa = async ({ logId, patientId }: IGetSingleQARequest) => {
  const res = await apiClient.get<IGetSingleQAResponse>(
    `patient/qamodel/logs/${patientId}/${logId}`
  );
  return res;
};

const clearHistory = async (patientId: number) => {
  const res = await apiClient.delete<IClearHistoryResponse>(`patient/qamodel/logs/${patientId}`);
  return res;
};

export const QuestionAnsweringService = {
  getAnswer,
  getQAHistory,
  getSingleQa,
  clearHistory,
};
