import { lazy } from "react";
import Login from "./auth/Login";
import Search from "./Search";
import ResetPassword from "./auth/ResetPassword";
import CreatePassword from "./auth/CreatePassword";

const Dashboard = lazy(() => import("./dashboard"));
const PatientsList = lazy(() => import("./PatientsList"));
const PatientInsights = lazy(() => import("./PatientInsights"));
const EvidenceViewer = lazy(() => import("./EvidenceViewer"));
const ClientDetail = lazy(() => import("./ClientDetail"));
const SettingsPage = lazy(() => import("./SettingsPage"));
const ProjectDetail = lazy(() => import("./ProjectDetail"));
const RoleMapping = lazy(() => import("./RoleMapping"));
const EnterpsieAdminConfigurations = lazy(() => import("./EnterpriseAdminConfigurations"));
const CohortPatients = lazy(() => import("./CohortPatients"));
const AnnotationProjectDetail = lazy(() => import("./AnnotationProjectDetail"));
const AnnotateFile = lazy(() => import("./AnnotateFile"));

export {
  Dashboard,
  Login,
  Search,
  EvidenceViewer,
  ClientDetail,
  SettingsPage,
  ProjectDetail,
  ResetPassword,
  CreatePassword,
  RoleMapping,
  PatientsList,
  PatientInsights,
  EnterpsieAdminConfigurations,
  AnnotateFile,
  AnnotationProjectDetail,
  CohortPatients,
};
