import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PATIENT_INSIGHT_TILES } from "..";
import { PastTreatmentProcedures } from "../../../../interfaces";
import { scoreToPercentage } from "../../../../utils";
import { INSIGHT_TYPE } from "../../../evidence-viewer/evidence-viewer-container";
import {
  CONFIDENCE_SCORE_INFO,
  EVIDENCE_VIEWER_URL,
  NoResultsFoundMessage,
  PatientCardWrapper,
  PatientInsightTile,
  ViewMoreResults,
} from "../tile-helper-components";

interface DataType {
  text: string;
  score: string;
  key: React.Key;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Source Text",
    dataIndex: "text",
    key: "text",
  },
  {
    title: "Confidence Score",
    filters: [],
    filterIcon: () => <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />,
    dataIndex: "score",
    key: "score",
  },
];

interface Props {
  pastTreatmentProcedures: PastTreatmentProcedures[];
  patientId: number;
  threshold: number;
}

export const PastTreatmentProceduresCard = ({
  pastTreatmentProcedures,
  patientId,
  threshold,
}: Props) => {
  const sorted = pastTreatmentProcedures.sort((a, b) => b.score - a.score);
  const aboveEighty = sorted.filter((a) => a.score >= threshold / 100);
  const belowEighty = sorted.filter((a) => a.score < threshold / 100);

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(
      `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.TEST_TREATMENT_PROCEDURE}`
    );
  };

  return (
    <>
      <PatientCardWrapper title={PATIENT_INSIGHT_TILES.pastTreatmentProcedure}>
        <div className="view-more-text">
          {/* <Table
            columns={columns}
            locale={{
              emptyText: (
                <NoResultsFoundMessage
                  aboveEightyLength={aboveEighty.length}
                  belowEightyLength={belowEighty.length}
                  threshold={threshold}
                />
              ),
            }}
            dataSource={
              aboveEighty
                ? aboveEighty.map((item, index) => ({
                    text: item.text,
                    score: scoreToPercentage(item.score),
                    key: `patient-pastTreatmentProcedures-${index}`,
                  }))
                : []
            }
            pagination={false}
            className="two-row"
            rowClassName={"cursor-pointer"}
            onRow={(record) => ({ onClick: () => handleSearchNavigate(record.text) })}
          ></Table> */}
          <div>
            {aboveEighty.length > 0 && (
              <div className="flex gp aic m-b">
                <h5 className="data-title">Past Treatment Procedures and Confidence Score</h5>
                <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />
              </div>
            )}
            <div className="data-two-column-score tiles-max-height">
              {aboveEighty?.map((item, index) => (
                <div
                  key={`patient-pastTreatmentProcedures-${index}`}
                  onClick={() => handleSearchNavigate(item.text)}
                >
                  <PatientInsightTile text={item.text} value={scoreToPercentage(item.score)} />
                </div>
              ))}
              <NoResultsFoundMessage
                aboveEightyLength={aboveEighty.length}
                belowEightyLength={belowEighty.length}
                threshold={threshold}
              />
            </div>
            <ViewMoreResults
              searchParanms={{
                search: [...new Set(belowEighty.map((a) => a.text))].join(","),
                patient_id: patientId,
              }}
              belowEightyLength={belowEighty.length}
              threshold={threshold}
            />
          </div>
        </div>
      </PatientCardWrapper>
    </>
  );
};
