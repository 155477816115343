import { useCallback, useState } from "react";
import { cohortService } from "../api/cohort";

export interface IFetchCohortObj {
  limit: number;
  offset: number;
}

export const useFetchCohorts = () => {
  const [loading, setLoading] = useState(false);
  const [cohorts, setCohorts] = useState([] as ICohort[]);
  const [total, setTotal] = useState(0);
  const fetchCohorts = useCallback(async (obj: IFetchCohortObj) => {
    setLoading(true);
    try {
      const qs = `${obj.offset}/${obj.limit}`;
      const { data } = await cohortService.getCohortList(qs);
      setCohorts([...data.data]);
      setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
    } finally {
      setLoading(false);
    }
  }, []);
  return { cohortsLoading: loading, fetchCohorts, cohorts, totalCohorts: total };
};
