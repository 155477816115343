import React, { useEffect, useMemo, useState } from "react";
import { Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PatientService } from "../../api";
import { RootState, setProjectPatients } from "../../store";
import { PAGE_URL } from "../../utils";
import "./patient-recommender.scss";

export enum FIT_STATUS {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

function getClass(value: string) {
  switch (value.toLowerCase()) {
    case FIT_STATUS.HIGH:
      return "green";
    case FIT_STATUS.MEDIUM:
      return "orange";
    case FIT_STATUS.LOW:
      return "red";
    default:
      return "green";
  }
}

export const PatientRecommender = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectPatientsList = useSelector((state: RootState) => state.project.detail.patients);
  const [loading, setLoading] = useState(false);

  const patientList = useMemo(() => {
    const countOfHighLowMedium = projectPatientsList.map((x) => {
      let high = 0,
        medium = 0,
        low = 0;
      Object.values(x).map((value) => {
        if (typeof value === "string") {
          switch (value.toLowerCase()) {
            case FIT_STATUS.HIGH:
              high += 1;
              break;
            case FIT_STATUS.MEDIUM:
              medium += 1;
              break;
            case FIT_STATUS.LOW:
              low += 1;
              break;
            default:
              break;
          }
        }
      });
      return { ...x, high, medium, low };
    });
    countOfHighLowMedium.sort((x, y) => {
      if (
        x.high > y.high ||
        (x.high === y.high && x.medium > y.medium) ||
        (x.high === y.high && x.medium === y.medium && x.low > y.low)
      )
        return -1;
      else if (
        x.high < y.high ||
        (x.high === y.high && x.medium < y.medium) ||
        (x.high === y.high && x.medium === y.medium && x.low < y.low)
      )
        return 1;
      else return 0;
    });
    return countOfHighLowMedium;
  }, [projectPatientsList]);

  useEffect(() => {
    const fetchPatientsList = async () => {
      setLoading(true);
      const { data } = await PatientService.getPatientsList();
      dispatch(setProjectPatients(data.list));
      setLoading(false);
    };
    fetchPatientsList();
  }, [dispatch]);

  return (
    <div className="patient-recommender">
      <div className="flex aic gp">
        <p className="demo-text basic-detail-subtitle">
          Demo purposes only, please contact your Autonomize representative to activate/learn more.
        </p>
      </div>
      <div className="basic-detail-content gp-top-20">
        <Table
          rowClassName="cursor-pointer"
          loading={loading}
          dataSource={patientList}
          pagination={false}
          className="cmn-table"
          bordered
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => navigate(`${PAGE_URL.PATIENT_PROFILE}/${record.PatientRelationId}`),
            };
          }}
        >
          <Column title="Patient Name" dataIndex="name" key="name" />
          <Column title="ID" dataIndex="patientId" key="id" />
          <Column
            title="Exclusion/Inclusion Criteria"
            dataIndex="exclusioninclusioncriteria"
            key="exclusioninclusioncriteria"
            render={(tag: string) => <Tag className={getClass(tag)}>{tag}</Tag>}
          />
          <Column
            title="Demographic Fit"
            dataIndex="demographicfit"
            key="demographicfit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
          <Column
            title="Geographic Fit"
            dataIndex="geographicfit"
            key="geographicfit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
          <Column
            title="Condition Fit"
            dataIndex="conditionfit"
            key="conditionfit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
          <Column
            title="Procedure Fit"
            dataIndex="procedurefit"
            key="procedurefit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
          <Column
            title="Physical Activity Fit"
            dataIndex="physicalactivityfit"
            key="physicalactivityfit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
        </Table>
      </div>
    </div>
  );
};
