import React from "react";
import { IMComprehend } from "../../../../../interfaces";
import { isEmptyObject } from "../../../../../utils";
import { useNavigate } from "react-router-dom";
import { EVIDENCE_VIEWER_URL } from "../../tile-helper-components";
import { INSIGHT_TYPE } from "../../../../evidence-viewer/evidence-viewer-container";

interface Props {
  mcomprehend: IMComprehend;
  patientId: number;
}

export const HedisMedicalEntites = ({ mcomprehend, patientId }: Props) => {
  const navigate = useNavigate();
  const handleMedicalConditionClick = (str: string) => {
    navigate(
      `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICAL_CONDITION}`
    );
  };

  const handleTestTreatmentProceduresClick = (str: string) => {
    navigate(
      `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.TEST_TREATMENT_PROCEDURE}`
    );
  };

  const handleIcdCodesTextClick = (str: string, icd_code: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&icd_code=${icd_code}`);
  };

  const handleMedicationClick = (str: string) => {
    navigate(
      `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICATION}`
    );
  };
  return (
    <div>
      <h4>Medical Entities</h4>
      <div className="flex gp-10 hedis-measure-container">
        {mcomprehend.medical_condition.length === 0 &&
          mcomprehend.test_treatment_procedures.length === 0 &&
          mcomprehend.icd_codes.length === 0 && <div>No medical entities found</div>}
        {mcomprehend.medical_condition.length !== 0 &&
          mcomprehend.medical_condition.map((item, index) => (
            <div
              className="cursor-pointer hedis-measure-item"
              key={`medical-condition-${index}`}
              onClick={() => handleMedicalConditionClick(item)}
            >
              {item}
            </div>
          ))}
        {mcomprehend.test_treatment_procedures.length !== 0 &&
          mcomprehend.test_treatment_procedures.map((item, index) => (
            <div
              className="cursor-pointer hedis-measure-item"
              key={`test-treatment-procedures-${index}`}
              onClick={() => handleTestTreatmentProceduresClick(item)}
            >
              {item}
            </div>
          ))}
        {mcomprehend.medications &&
          mcomprehend.medications.length !== 0 &&
          mcomprehend.medications.map((item, index) => (
            <div
              className="cursor-pointer hedis-measure-item"
              key={`medication-${index}`}
              onClick={() => handleMedicationClick(item)}
            >
              {item}
            </div>
          ))}
        {mcomprehend.icd_codes.length !== 0 &&
          mcomprehend.icd_codes.map(
            (item, index) =>
              !isEmptyObject(item) && (
                <div
                  className="cursor-pointer hedis-measure-item"
                  key={`icd-code-${index}`}
                  onClick={() => handleIcdCodesTextClick(item.text, item.icd_code)}
                >
                  {item.text} <span>({item.icd_code})</span>
                </div>
              )
          )}
      </div>
    </div>
  );
};
