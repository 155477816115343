import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { projectService, RecentDocument, RecentDocumentStatus } from "../../api";
import { InfiniteScrollTable } from "../../components";
import { setProjectDocumentDetail, openModal } from "../../store";
import { DOCUMENT_STATUS, PAGE_URL, getLocalDateTimeStringFromISO, MODAL_TYPE } from "../../utils";

interface Props {
  projectId: number;
}

const getClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.COMPLETED:
      return "#44C658";
    case DOCUMENT_STATUS.ERROR:
      return "red";
    default:
      return "#475569";
  }
};

interface DataType {
  key: React.Key;
  name: string;
  completedOn: string;
  createdOn: string;
  status: string;
  // action: string;
  statusList: RecentDocumentStatus[];
}

const getColumns = (
  dispatch: Dispatch<AnyAction>,
  navigate: NavigateFunction
): ColumnsType<DataType> => [
  {
    title: "Filename & ID",
    key: "name",
    dataIndex: "name",
    render: (item, record) => (
      <p
        className="cc flex cursor-pointer"
        onClick={() =>
          record.status === DOCUMENT_STATUS.COMPLETED &&
          navigate(`${PAGE_URL.EVIDENCE_VIEWER}?fileId=${record.key}`)
        }
      >
        {item}
      </p>
    ),
  },
  {
    title: "Uploaded on ",
    key: "createdOn",
    dataIndex: "createdOn",
    render: (item) => <p>{item ? getLocalDateTimeStringFromISO(item) : ""}</p>,
  },
  {
    title: "Completed on ",
    key: "completedOn",
    dataIndex: "completedOn",
    render: (item) => <p>{item ? getLocalDateTimeStringFromISO(item) : ""}</p>,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text, value) => (
      <>
      <div>
          <div className="status" style={{ color: getClass(value.status) }}>
            <p style={{ color: getClass(value.status) }}>{value.status}</p>{" "}
          </div>
          <div
            className="editDelete cursor-pointer"
            onClick={() => {
              const { completedOn, createdOn, name, status, statusList, key } = value;
              dispatch(
                setProjectDocumentDetail({
                  bucketName: "",
                  documentCompletedOn: completedOn,
                  documentCreatedOn: createdOn,
                  objectName: name,
                  documentId: key.toString(),
                  documentStatus: status,
                  statusList: statusList,
                })
              );
              dispatch(openModal(MODAL_TYPE.PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS));
            }}
          >
            {/* <a>Check</a> */}
          </div>
        </div>
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (text, record: DataType) => (
      <div
        className="editDelete cursor-pointer"
        onClick={() => {
          const { completedOn, createdOn, name, status, statusList, key } = record;
          dispatch(
            setProjectDocumentDetail({
              bucketName: "",
              documentCompletedOn: completedOn,
              documentCreatedOn: createdOn,
              objectName: name,
              documentId: key.toString(),
              documentStatus: status,
              statusList: statusList,
            })
          );
          dispatch(openModal(MODAL_TYPE.PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS));
        }}
      >
        <a>View More</a>
      </div>
    ),
  },
];

export const RecentDocuments = ({ projectId }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([] as RecentDocument[]);
  const [nextPageIndexKey, setNextPageIndexKey] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const documentsList: DataType[] = list.map((item) => {
    const {
      documentCompletedOn,
      documentCreatedOn,
      documentId,
      documentStatus,
      objectName,
      statusList,
    } = item;
    return {
      key: documentId,
      name: objectName,
      completedOn: documentCompletedOn && documentCompletedOn.split(" ")[0],
      createdOn: documentCreatedOn && documentCreatedOn.split(" ")[0],
      status: documentStatus,
      // action: "",
      statusList,
    };
  });

  const fetchFiles = useCallback(
    async (indexKey = "", isAppend = false) => {
      if (projectId) {
        setLoading(true);
        const { data } = await projectService.getFiles(projectId, 20, indexKey);
        setLoading(false);
        if (isAppend) return data;
        if (data.response.list.length === 0) setHasMore(false);
        setList(data.response.list);
        setNextPageIndexKey(
          data.response.LastEvaluatedKey ? data.response.LastEvaluatedKey.documentId : ""
        );
      }
    },
    [projectId]
  );

  const appendFiles = async () => {
    const data = await fetchFiles(nextPageIndexKey, true);
    if (data && data.response.LastEvaluatedKey) {
      setList(list.concat(data.response.list));
      setNextPageIndexKey(data.response.LastEvaluatedKey.documentId);
    } else setHasMore(false);
  };

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <div className="recent-document">
      <h2 className="basic-detail-title">
        Recent Documents {list.length ? `(${list.length})` : ""}
      </h2>
      <div className="client-table basic-detail-content">
        <InfiniteScrollTable
          columns={getColumns(dispatch, navigate)}
          dataSource={documentsList}
          onUpdate={appendFiles}
          pagination={false}
          hasMore={hasMore}
          loading={loading}
        />
      </div>
    </div>
  );
};
