import React from "react";
import { IQA } from "../../../../../interfaces";
import { QATag } from "./QATag";

interface Props {
  qa: IQA[];
}

export const HedisQA = ({ qa }: Props) => {
  return (
    <div>
      <h4>Key Insights Derived</h4>
      <div className="hedis-ques-ans">
        {qa.length !== 0 ? (
          qa.map((item, index) => <QATag key={`qa-${index}`} qa={item} />)
        ) : (
          <div>No key insights found.</div>
        )}
      </div>
    </div>
  );
};
