import React from "react";
import { useNavigate } from "react-router-dom";
import { CMSValues } from "../../../../interfaces";
import "../patientprofile.scss";
import {
  EVIDENCE_VIEWER_URL,
  PatientCardWrapper,
  NoResultsFoundMessage,
} from "../tile-helper-components";
import { PATIENT_INSIGHT_TILES } from "..";

interface Props {
  threshold: number;
  patientId: number;
  cmsValues: CMSValues[];
}

export const CMS2728InsightsCard = ({ patientId, cmsValues }: Props) => {
  if (!cmsValues || cmsValues.length == 0) {
    cmsValues = [];
  }

  let isQuestionPresent = false;

  cmsValues.forEach((cmsValue) => {
    if (cmsValue.Question) isQuestionPresent=true
  })

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string, fileId: string, beginOffset=0, endOffset=0) => {
    if (beginOffset !=0 && endOffset !=0 ) {
      navigate(
        `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&beginOffset=${beginOffset}&endOffset=${endOffset}&checkLegend=false&fileId=${fileId}`
      );
    }
    else {
      navigate(
        `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&checkLegend=false&fileId=${fileId}`
      );
    }
  };

  return (
    <>
      <PatientCardWrapper title="CMS 2728 - Key Insights Derived">
        <div className="">
          {cmsValues.length > 0 && isQuestionPresent ? (
            cmsValues.map((object, i) => {
              return (<div style={{ display: "grid" }} key={`question-answer-${i}`}><p style={{ fontFamily: 'system-ui', cursor: 'pointer' }} onClick={() => handleSearchNavigate(object.Question, object.fileId)}><b>Question:-</b> {object.Question}</p><hr style={{opacity: '30%'}}/><p style={{ fontFamily: 'system-ui', cursor: 'pointer' }} onClick={() => handleSearchNavigate(object.Answer, object.fileId, object.A_BeginOffset, object.A_EndOffset)}><b>Answer:-</b> {object.Answer}</p><br /></div>)
            })
          ) : (
            <NoResultsFoundMessage
              aboveEightyLength={0}
              belowEightyLength={0}
            />
          )}
        </div>
      </PatientCardWrapper>
    </>
  );
};
