import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, RootState, setUserRoles } from "../../store";
import { UsersList } from "..";
import { rolesService } from "../../api/roles";
import { useFetchUserList } from "../../hooks";
import { Button } from "antd";
import { MODAL_TYPE } from "../../utils";

export const EnterpriseAdminDashboardContainer = () => {
  const dispatch = useDispatch();
  const { fetchUsers } = useFetchUserList();
  const [loading, setLoading] = useState(true);
  const clientId = useSelector((state: RootState) => state.user.user.clientId);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await fetchUsers();
      const { data: userRoles } = await rolesService.getAll(true);
      dispatch(setUserRoles(userRoles.data));
      setLoading(false);
    };
    fetch();
  }, [clientId, dispatch, fetchUsers]);

  return (
    <div className="projectsList">
      <div className="flex jcsb aic ">
        <p className="table-heading">Users</p>
        <Button
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER));
          }}
        >
          Add New User
        </Button>
      </div>
      <UsersList loading={loading} />
    </div>
  );
};
