import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { SearchForm } from "./search-container";

export const SearchLayoutContainer = () => {
  const [callApi, setCallApi] = useState(false);

  return (
    <>
      <SearchForm setCallApi={setCallApi} />
      <Outlet context={[callApi, setCallApi]} />
    </>
  );
};

export { SearchHome, SearchResult } from "./search-container";
