import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setProjects } from "../../../store";
import { ProjectTile } from "./ProjectTile";
import { projectService } from "../../../api";
import { useUser } from "../../../hooks";
import { Loading } from "../../../components";

interface Props {
  className: string;
}

export const ProjectListInTiles = ({ className }: Props) => {
  const { projects } = useSelector((state: RootState) => state.data);
  const { clientId } = useUser();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const { data } = await projectService.getAll(clientId);
      const { data: projectList } = data;
      dispatch(setProjects(projectList));
      setLoading(false);
    };
    fetch();
  }, [clientId, dispatch]);

  return (
    <>
      <div className={`grid-View m-t ${className}`}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {projects.list.map((project, index) => (
              <ProjectTile key={`project-tile-${index}`} project={project} />
            ))}
          </>
        )}
      </div>
    </>
  );
};
